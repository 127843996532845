<div class="mat-typography">
  <h1 mat-dialog-title>{{'dialog.contact.us'|loc}}</h1>
  <div mat-dialog-content>
    <div *ngIf="!contactSent" class="contact">
      <h4 *ngIf="topic != 'registrationKey'">{{'dialog.contact.us.description'|loc}}</h4>
      <form [formGroup]="contactForm">
        <div class="two-col-grid">
          <label for="company">{{'dialog.contact.us.company'|loc}}<span class="text-danger">&nbsp;*</span></label>
          <mat-form-field appearance="fill">
            <input matInput aria-label="company" aria-describedby="company" formControlName="company" id="company"
              [ngClass]="{ 'is-invalid': isCompanyInvalid() }">
            <mat-error>{{'dialog.contact.us.company.error.required'|loc}}</mat-error>
          </mat-form-field>

          <label for="name">{{'dialog.contact.us.name'|loc}}<span class="text-danger">&nbsp;*</span></label>
          <mat-form-field appearance="fill">
            <input matInput aria-label="name" aria-describedby="name" id="name"
              formControlName="name"
              [ngClass]="{ 'is-invalid': isNameInvalid() }">
            <mat-error>{{'dialog.contact.us.name.error.required'|loc}}</mat-error>
          </mat-form-field>

          <label for="email">{{'dialog.contact.us.email'|loc}}<span class="text-danger">&nbsp;*</span></label>
          <mat-form-field appearance="fill">
            <input matInput aria-label="email" aria-describedby="email" id="email"
              formControlName="email"
              [ngClass]="{ 'is-invalid': isEmailInvalid() }">
              <mat-error>{{'dialog.contact.us.email.error.required'|loc}}</mat-error>
          </mat-form-field>

          <label for="purpose">{{'dialog.contact.us.purpose'|loc}}<span class="text-danger">&nbsp;*</span></label>
          <mat-form-field appearance="fill">
            <mat-select formControlName="purpose" name="purpose" class="regular" id="purpose">
              <mat-option *ngFor="let mp of purposes" [value]="mp.id">
                {{mp.label}}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <label class="col-form-label" for="message">{{'dialog.contact.us.message'|loc}}<span class="text-danger">&nbsp;*</span></label>
          <mat-form-field appearance="fill">
            <textarea matInput rows="6" formControlName="message" id="message"></textarea>
            <mat-error>{{'dialog.contact.us.message.error.required'|loc}}</mat-error>
          </mat-form-field>
        </div>
      </form>
    </div>
    <div *ngIf="contactSent">
      <div class="row">
        <p>{{'dialog.contact.us.success'|loc}}</p>
        <p><a class="link-info" (click)="reset()">{{'dialog.contact.us.send.another'|loc}}</a></p>
      </div>
    </div>
  </div>
  <div mat-dialog-actions align="end" *ngIf="!contactSent">
    <button mat-button mat-dialog-close>{{'dialog.contact.us.close'|loc}}</button>
    <button mat-raised-button color="accent" (click)="submit()" [disabled]="sending || contactForm.get('message')?.errors">
      <span *ngIf="!sending">{{'dialog.contact.us.send'|loc}}</span>
      <span *ngIf="sending">{{'dialog.contact.us.sending'|loc}}</span>
    </button>
  </div>
</div>
