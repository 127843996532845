import { createFeature, createReducer, on } from "@ngrx/store";
import { Artist, NameVariant, Relationship } from "src/app/model";
import { artistActions } from "./artist.actions";
import { Skeleton, Status, skeleton } from "../state";
import { toArtistFromXParty } from "./artist.domain";

export interface ArtistState {
  artistsDisambiguation: {artists: Artist[]|Skeleton[], searchTerm: string|null};
  artist: Artist|null;
  status: Status;
  error: string|null;

  nameVariants: NameVariant[];
  nameVariantsStatus: Status;
  relationships: Relationship[];
  relationshipsStatus: Status;
}

export const initialState: ArtistState = {
  artistsDisambiguation: {artists: skeleton(4), searchTerm: null},
  artist: null,
  nameVariants: [],
  relationships: [],
  nameVariantsStatus: 'idle',
  relationshipsStatus: 'idle',
  status: 'idle',
  error: null
};

export const artistFeature = createFeature({
  name: 'artist',
  reducer: createReducer(
    initialState,
    on(artistActions.getartistbyquansicid, (state) => ({
      ...state,
      artist: null,
      nameVariants: [],
      relationships: [],
      nameVariantsStatus: 'idle' as Status,
      relationshipsStatus: 'idle' as Status,
      status: 'loading' as Status,
      error: null
    })),
    on(artistActions.getartistbyquansicidsuccess, (state, {xParty, ySources}) => ({
      ...state,
      artist: xParty ? {...toArtistFromXParty(xParty), ySources} : null,
      status: 'success' as Status
    })),
    on(artistActions.getartistbyquansicidfailure, (state, {error}) => ({
      ...state,
      status: 'error' as Status,
      error
    })),
    on(artistActions.getartistsbyname, (state) => ({
      ...state,
      status: 'idle' as Status,
      error: null,
      artistsDisambiguation: {artists: skeleton(4), searchTerm: null},
    })),
    on(artistActions.getnamevariants, (state) => ({
      ...state,
      nameVariantsStatus: 'loading' as Status
    })),
    on(artistActions.getnamevariantssuccess, (state, {nameVariants}) => ({
      ...state,
      nameVariants,
      nameVariantsStatus: 'success' as Status
    })),
    on(artistActions.getnamevariantserror, (state, {error}) => ({
      ...state,
      error,
      nameVariantsStatus: 'error' as Status
    })),

    on(artistActions.getrelationships, (state) => ({
      ...state,
      relationshipsStatus: 'loading' as Status,
      error: null
    })),
    on(artistActions.getrelationshipssuccess, (state, {relationships}) => ({
      ...state,
      relationships,
      relationshipsStatus: 'success' as Status
    })),
    on(artistActions.getrelationshipserror, (state, {error}) => ({
      ...state,
      error,
      relationshipsStatus: 'error' as Status
    })),
    on(artistActions.getartistsbyid, (state) => ({
      ...state,
      status: 'loading' as Status,
      artist: null,
      artistsDisambiguation: {artists: skeleton(4), searchTerm: null},
    })),
    on(artistActions.getartistsbyidmanysuccess, (state, {xParties, searchTerm}) => ({
      ...state,
      artistsDisambiguation: {
        artists: xParties.map(toArtistFromXParty),
        searchTerm
      }
    })),
    on(artistActions.getartistsbyidfailure, (state, {error, searchTerm}) => {
      return ({
        ...state,
        status: 'error' as Status,
        error,
        artistsDisambiguation: {
          artists: [],
          searchTerm
        }
      })
    })
  )
})
