import { Component, Input } from "@angular/core";
import { CrtcMaiplScore, MaiplCategory } from "../services/crtc-model";

@Component({
  selector: 'maipl-score',
  templateUrl: './maipl-score.component.html',
  styleUrls: ['./maipl-score.component.scss']
})
export class MaiplScoreComponent {
  @Input('score') score: CrtcMaiplScore|null = null;

  isSet(category: MaiplCategory) {
    return this.score?.[category]
  }
}
