<!-- (change)="editMaiplScore(crtcMaiplScore, 'music')" -->
<div class="maipl" [formGroup]="formGroup">
      <!-- <mat-list [formGroup]="formGroup" >
        <mat-list-item>
            <mat-checkbox color="accent" formControlName="maiplScoreMusic"
            (click)="maiplScoreRulesService.overrideScore('music')">Music</mat-checkbox>
        </mat-list-item>
        <mat-list-item >
          <mat-checkbox  color="accent" formControlName="maiplScoreArtist"
          (click)="maiplScoreRulesService.overrideScore('artist')">Artist</mat-checkbox>
        </mat-list-item>
        <mat-list-item>
          <mat-checkbox  color="accent" formControlName="maiplScoreInstr"
          (click)="maiplScoreRulesService.overrideScore('instrument')">Instrument</mat-checkbox>
        </mat-list-item>
        <mat-list-item>
          <mat-checkbox color="accent" formControlName="maiplScorePerf"
          (click)="maiplScoreRulesService.overrideScore('performance')">Performance</mat-checkbox>
        </mat-list-item>
        <mat-list-item>
          <mat-checkbox  color="accent" formControlName="maiplScoreLyrics"
          (click)="maiplScoreRulesService.overrideScore('lyrics')">Lyrics</mat-checkbox>
        </mat-list-item>
        <mat-divider></mat-divider>
        <mat-list-item>
          <mat-checkbox formControlName="isCancon" color="accent"
          (click)="maiplScoreRulesService.overrideIsCancon()">Is Cancon ?</mat-checkbox>
        </mat-list-item>
      </mat-list> -->
  <div>
      <mat-checkbox color="accent" formControlName="maiplScoreMusic"
      (click)="maiplScoreRulesService.overrideScore('music')">
      {{'recording.crtc.maipl.score.music'|loc}}
      </mat-checkbox>
  </div>
  <div>
    <mat-checkbox  color="accent" formControlName="maiplScoreArtist"
    (click)="maiplScoreRulesService.overrideScore('artist')">
    {{'recording.crtc.maipl.score.artist'|loc}}
    </mat-checkbox>
  </div>
  <div>
    <mat-checkbox  color="accent" formControlName="maiplScoreInstr"
    (click)="maiplScoreRulesService.overrideScore('instrument')">
    {{'recording.crtc.maipl.score.instrument'|loc}}
    </mat-checkbox>
  </div>
  <div>
    <mat-checkbox color="accent" formControlName="maiplScorePerf"
    (click)="maiplScoreRulesService.overrideScore('performance')">
    {{'recording.crtc.maipl.score.performance'|loc}}
    </mat-checkbox>
  </div>
  <div>
    <mat-checkbox  color="accent" formControlName="maiplScoreLyrics"
    (click)="maiplScoreRulesService.overrideScore('lyrics')">
    {{'recording.crtc.maipl.score.lyrics'|loc}}
    </mat-checkbox>
  </div>
  <div>
    <mat-checkbox formControlName="isCancon" color="accent"
    (click)="maiplScoreRulesService.overrideIsCancon()">  
    {{'recording.crtc.maipl.is.cancon'|loc}} ?
    </mat-checkbox>
  </div>
</div>