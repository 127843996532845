

<ng-container *ngIf="artistsResults$|async as results">
  <h1 *ngIf="results.noMatch">
    We can't find any artist with this name. Please try a different spelling or
    <a (click)="openFeedbackDialog()" href="javascript: return false;">report a missing artist</a>
  </h1>
  <ng-container *ngIf="!results.noMatch">
    <div class="top-results-container" >
      <h1>Top Results</h1>
      <div class="results top-results">
        <div class="grid-view">
          <ng-container *ngFor="let artist of results.topResultsArtists" >
            <disambig-item [artist]="artist" matRipple></disambig-item>
          </ng-container>
          <ng-container *ngFor="let skeleton of results.skeletons" >
            <disambig-item [skeleton]="skeleton" matRipple></disambig-item>
          </ng-container>
        </div>
      </div>
    </div>
    <div class="results-container" [ngClass]="{'hide': results.otherResultsArtists.length == 0}">
      <h1>Other Results
        <span class="view-select">
          <mat-icon [ngClass]="{'active':  isGridView()}" (click)="setViewToGrid()">grid_view</mat-icon>
          <mat-icon [ngClass]="{'active':  isListView()}" (click)="setViewToList()">list</mat-icon>
        </span>
      </h1>
      <div class="results">
        <div class="grid-view" [ngClass]="{'hide': isListView()}">
          <disambig-item [artist]="artist" *ngFor="let artist of results.otherResultsArtists" class="artist link"  matRipple></disambig-item>
        </div>
        <div class="list-view" [ngClass]="{'hide': isGridView()}" style="padding-left: 50px">
          <mat-table [dataSource]="otherResultsDatasource" matSort>
            <!-- <ng-container matColumnDef="cover">
              <mat-header-cell *matHeaderCellDef> </mat-header-cell>
              <mat-cell *matCellDef="let artist">
                <img class="circle-mask circle-mask-mini"  [src]="imageService.convertImagePath(artist.cover) || '/assets/images/no-artist.jpg'"></mat-cell>
            </ng-container> -->
            <ng-container matColumnDef="name">
              <mat-header-cell *matHeaderCellDef mat-sort-header> Name </mat-header-cell>
              <mat-cell *matCellDef="let artist">
                <span class="artist link" [routerLink]="['/app-party', artist.ids.quansicId]" >{{artist.name}}</span>
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="type">
              <mat-header-cell *matHeaderCellDef mat-sort-header> Type </mat-header-cell>
              <mat-cell *matCellDef="let artist"> {{artist.type}} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="gender">
              <mat-header-cell *matHeaderCellDef mat-sort-header> Gender </mat-header-cell>
              <mat-cell *matCellDef="let artist"> {{artist.gender}} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="nationality" >
              <mat-header-cell *matHeaderCellDef mat-sort-header disableClear> Nationality </mat-header-cell>
              <mat-cell *matCellDef="let artist"> {{artist.nationality}} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="comments" >
              <mat-header-cell *matHeaderCellDef mat-sort-header disableClear> Comments </mat-header-cell>
              <mat-cell *matCellDef="let artist"> {{artist.comments}} </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="listViewProps.columns"></mat-header-row>
            <mat-row matRipple *matRowDef="let artist; columns: listViewProps.columns;"></mat-row>
          </mat-table>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>
