<mat-toolbar>
  <a mat-button href="javascript:history.go(-1);" aria-label="Navigate to previous page"><mat-icon>arrow_back</mat-icon></a>
  <search format="mini" *ngIf="shouldDisplay"></search>
  <span class="spacer"></span>
  <div class="main-title">
    <span style="margin-right: 15px"><a [routerLink]="['/app-home']" aria-label="Home"><img src="assets/images/quansic_explorer.png" height="32" aria-label="Logo Quansic"></a></span>
    <span>DATA EXPLORER by Quansic</span>
    <div *ngIf="isCrtcUser()" class="crtc-edition">
      CRTC Edition
      <img src="assets/images/canada-flag.png" height="24" alt="Canada flag">
    </div>
  </div>
  <span class="spacer"></span>
  <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Data-Explorer Menu">
    <mat-icon>menu</mat-icon>
  </button>
  <mat-menu #menu="matMenu" id="main-menu">
    <button mat-menu-item aria-label="Report an error"  (click)="openFeedbackDialog()">
      <span>Report an Error</span>
    </button>
    <button mat-menu-item aria-label="Contact Us" (click)="openContactUsDialog()">
      <span>Contact Us</span>
    </button>
    <button mat-menu-item aria-label="Help" (click)="openHelpDialog()">
      <span>Help</span>
    </button>
    <button mat-menu-item aria-label="Privacy Policy" (click)="openPrivacyPolicyDialog()">
      <span>Privacy Policy</span>
    </button>
    <button mat-menu-item aria-label="Terms & Conditions"  (click)="openTermsConditionsDialog()">
      <span>Terms & Conditions</span>
    </button>
    <button mat-menu-item aria-label="Logout" (click)="logout()">
      <span>Logout</span>
    </button>
    <span style="padding-inline: 1.9em; font-size: 0.7em">v{{version}}</span>
  </mat-menu>
</mat-toolbar>
