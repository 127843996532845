<div class="relationships">
  <div class="title" loc="artist.name-variants">Name Variants</div>
  <div class="grid grid-four-col">
    <ng-container *ngIf="status$|async as status">
      <skeleton *ngIf="status === 'idle' || status == 'loading'"></skeleton>
      <ng-container *ngIf="status === 'success'">
        <ng-container *ngIf="nameVariants$|async as nameVariants">
          <div *ngFor="let nameVariant of nameVariants | sortBy:'asc':'name'">
              {{nameVariant.fullname}} <span *ngIf="nameVariant.language">({{nameVariant.language.toLowerCase()}})</span>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>
</div>
