import { FormGroup } from "@angular/forms";
import { MaiplCategory } from "src/app/crtc/services/crtc-model";

export type MaiplScoreRuleChangeRequest = {
    property: string;
    originalValue: boolean;
    newValue: boolean;
}

export type MaiplScoreRule = {
    id: string;
    description: string;
    changeRequest: MaiplScoreRuleChangeRequest|null;
    predicate: (formGroup: FormGroup, overrides: MaiplScoreOverrides) => boolean;
    apply: (formGroup: FormGroup) => void;
}

export type MaiplScoreRuleResult = {
    rules : {
        id: string;
        description: string;
        isApplied: boolean;
    }[]
    changeRequests?: MaiplScoreRuleChangeRequest[];
}

export type MaiplScoreChanges = Partial<{
    maiplScoreMusic: boolean | null;
    maiplScoreArtist: boolean | null;
    maiplScorePerf: boolean | null;
    maiplScoreInstr: boolean | null;
    maiplScoreLyrics: boolean | null;
    isCancon: boolean | null;
}>

export type MaiplScoreOverrides = Partial<{
    [key in MaiplCategory]: boolean;
}> & Partial<{isCancon: boolean}>


const lyricsNoInstrument: MaiplScoreRule = {
    id: 'lyrics-no-instrument',
    description: 'Lyrics cannot be set if Instrument is set',
    predicate: (formGroup: FormGroup, overrides: MaiplScoreOverrides) => {
        return formGroup.get('maiplScoreInstr')?.value === true;
    },
    apply: (formGroup: FormGroup) => {
        formGroup.get('maiplScoreLyrics')?.setValue(false, {emitEvent: false})
        formGroup.get('maiplScoreLyrics')?.disable({emitEvent: false})
    },
    changeRequest: null
}

const noLyricsNoInstrument: MaiplScoreRule = {
    id: 'no-lyrics-no-instrument',
    description: 'Lyrics can be set if Instrument is not set',
    predicate: (formGroup: FormGroup) => {
        return formGroup.get('maiplScoreInstr')?.value === false
    },
    apply: (formGroup: FormGroup) => {
        formGroup.get('maiplScoreLyrics')?.enable({emitEvent: false})
    },
    changeRequest: null
}

const instrumentNoLyrics: MaiplScoreRule = {
    id: 'instrument-no-lyrics',
    description: 'Instrument cannot be set if Lyrics is set',
    predicate: (formGroup: FormGroup, overrides: MaiplScoreOverrides) => {
        return formGroup.get('maiplScoreLyrics')?.value === true;
    },
    apply: (formGroup: FormGroup) => {
        formGroup.get('maiplScoreInstr')?.setValue(false, {emitEvent: false})
        formGroup.get('maiplScoreInstr')?.disable({emitEvent: false})
    },
    changeRequest: null
}
const noInstrumentNoLyrics: MaiplScoreRule ={
    id: 'no-instrument-no-lyrics',
    description: 'Instrument can be set if Lyrics is not set',
    predicate: (formGroup: FormGroup, overrides: MaiplScoreOverrides) => {
        return formGroup.get('maiplScoreLyrics')?.value === false
    },
    apply: (formGroup: FormGroup) => {
        formGroup.get('maiplScoreInstr')?.enable({emitEvent: false})
    },
    changeRequest: null
}

const isNotCancon: MaiplScoreRule = {
    id: 'is-not-cancon',
    description: 'Is Not Canadian Content',
    predicate: (formGroup: FormGroup, overrides: MaiplScoreOverrides) => {
        if(overrides.isCancon === true) return false;
        const music = formGroup.get('maiplScoreMusic')?.value 
        const artist = formGroup.get('maiplScoreArtist')?.value
        const instr = formGroup.get('maiplScoreInstr')?.value
        const perf = formGroup.get('maiplScorePerf')?.value
        const lyrics = formGroup.get('maiplScoreLyrics')?.value


        return (!music && !lyrics) 
            || (!artist && !music)
            || (!artist && !perf && !instr)
            || (!artist && !perf)
            || (!music && !perf)
            || (!lyrics && !perf)
            || (!music && !instr)
    },
    apply: (formGroup: FormGroup) => {
        formGroup.get('isCancon')?.setValue(false, {emitEvent: false})
    },
    changeRequest: {
        property: 'isCanadianContent',
        originalValue: true,
        newValue: false
    }
}

const isCanconML: MaiplScoreRule ={
    id: 'is-cancon-M+L',
    description: 'Is Canadian Content if Music and Lyrics are set',
    predicate: (formGroup: FormGroup, overrides: MaiplScoreOverrides) => {
        if(overrides.isCancon === true) return false;
        return formGroup.get('maiplScoreMusic')?.value 
            && formGroup.get('maiplScoreLyrics')?.value
    },
    apply: (formGroup: FormGroup) => {
        formGroup.get('isCancon')?.setValue(true, {emitEvent: false})
    },
    changeRequest: {
        property: 'isCanadianContent',
        originalValue: false,
        newValue: true
    }
}

const isCanconAM: MaiplScoreRule ={
    id: 'is-cancon-A+M',
    description: 'Is Canadian Content if Artist and Music are set',
    predicate: (formGroup: FormGroup, overrides: MaiplScoreOverrides) => {
        if(overrides.isCancon === true) return false;
        return formGroup.get('maiplScoreMusic')?.value 
            && formGroup.get('maiplScoreArtist')?.value
    },
    apply: (formGroup: FormGroup) => {
        formGroup.get('isCancon')?.setValue(true, {emitEvent: false})
    },
    changeRequest: {
        property: 'isCanadianContent',
        originalValue: false,
        newValue: true
    } 
}

const isCanconAL: MaiplScoreRule ={
    id: 'is-cancon-A+L',
    description: 'Is Canadian Content if Artist and Lyric are set',
    predicate: (formGroup: FormGroup, overrides: MaiplScoreOverrides) => {
        if(overrides.isCancon === true) return false;
        return formGroup.get('maiplScoreLyrics')?.value 
            && formGroup.get('maiplScoreArtist')?.value
    },
    apply: (formGroup: FormGroup) => {
        formGroup.get('isCancon')?.setValue(true, {emitEvent: false})
    },
    changeRequest: {
        property: 'isCanadianContent',
        originalValue: false,
        newValue: true
    } 
}
const isCanconAPI: MaiplScoreRule ={
    id: 'is-cancon-A+P+I',
    description: 'Is Canadian Content if Artist and Performance and Lyrics are set',
    predicate: (formGroup: FormGroup, overrides: MaiplScoreOverrides) => {
        if(overrides.isCancon === true) return false;
        return formGroup.get('maiplScoreArtist')?.value 
            && formGroup.get('maiplScorePerf')?.value
            && formGroup.get('maiplScoreLyrics')?.value
    },
    apply: (formGroup: FormGroup) => {
        formGroup.get('isCancon')?.setValue(true, {emitEvent: false})
    },
    changeRequest: {
        property: 'isCanadianContent',
        originalValue: false,
        newValue: true
    } 
}
const isCanconAP: MaiplScoreRule ={
    id: 'is-cancon-A+P',
    description: 'Is Canadian Content if Artist and Performance are set',
    predicate: (formGroup: FormGroup, overrides: MaiplScoreOverrides) => {
        if(overrides.isCancon === true) return false;
        return formGroup.get('maiplScoreArtist')?.value 
            && formGroup.get('maiplScorePerf')?.value
    },
    apply: (formGroup: FormGroup) => {
        formGroup.get('isCancon')?.setValue(true, {emitEvent: false})
    },
    changeRequest: {
        property: 'isCanadianContent',
        originalValue: false,
        newValue: true
    } 
}
const isCanconMP: MaiplScoreRule ={
    id: 'is-cancon-M+P',
    description: 'Is Canadian Content if Music and Performance are set',
    predicate: (formGroup: FormGroup, overrides: MaiplScoreOverrides) => {
        if(overrides.isCancon === true) return false;
        return formGroup.get('maiplScoreMusic')?.value 
            && formGroup.get('maiplScorePerf')?.value
    },
    apply: (formGroup: FormGroup) => {
        formGroup.get('isCancon')?.setValue(true, {emitEvent: false})
    },
    changeRequest: {
        property: 'isCanadianContent',
        originalValue: false,
        newValue: true
    }
}

const isCanconLP: MaiplScoreRule = {
    id: 'is-cancon-L+P',
    description: 'Is Canadian Content if Lyrics and Performance are set',
    predicate: (formGroup: FormGroup, overrides: MaiplScoreOverrides) => {
        if(overrides.isCancon === true) return false;
        return formGroup.get('maiplScoreLyrics')?.value 
            && formGroup.get('maiplScorePerf')?.value
    },
    apply: (formGroup: FormGroup) => {
        formGroup.get('isCancon')?.setValue(true, {emitEvent: false})
    },
    changeRequest: {
        property: 'isCanadianContent',
        originalValue: false,
        newValue: true
    }
}
const isCanconMI: MaiplScoreRule = {
    id: 'is-cancon-M+I',
    description: 'Is Canadian Content if Music and Instrument are set',
    predicate: (formGroup: FormGroup, overrides: MaiplScoreOverrides) => {
        if(overrides.isCancon === true) return false;
        return formGroup.get('maiplScoreMusic')?.value 
            && formGroup.get('maiplScoreInstr')?.value
    },
    apply: (formGroup: FormGroup) => {
        formGroup.get('isCancon')?.setValue(true, {emitEvent: false})
    },
    changeRequest: {
        property: 'isCanadianContent',
        originalValue: false,
        newValue: true
    }
}

export const rules: MaiplScoreRule[] = [
    lyricsNoInstrument,
    noLyricsNoInstrument,
    instrumentNoLyrics,
    noInstrumentNoLyrics,
    // isNotCancon,
    isCanconML,
    isCanconAM,
    isCanconAL,
    isCanconAPI,
    isCanconAP,
    isCanconMP,
    isCanconLP,
    isCanconMI
] 