<ng-container *ngIf="status$|async as status">
    <ng-container *ngIf="dataSource.data.length === 0">
        <table-empty *ngIf="['success', 'error'].includes(status)" entity="recording"></table-empty>
        <mat-progress-bar mode="indeterminate"*ngIf="['loading', 'idle'].includes(status)" color="accent" style="margin-block: 1rem"></mat-progress-bar>
    </ng-container>
    
    <ng-container *ngIf="dataSource.data.length > 0">
        <div style="display: flex; gap: 1rem; align-items: center; justify-content: space-between;">
            <h3 style="margin-block-end: 0;">Top 10 recordings (by streams)</h3>
            <a (click)="viewAllRecordings()" style="cursor: pointer">View all recordings ({{recordingsTotal$|async}})</a>
        </div>
        <mat-table [dataSource]="dataSource" matSort [trackBy]="trackByIsrc" >
            <ng-container matColumnDef="audio" >
                <mat-header-cell *matHeaderCellDef> {{'recording.audio'|loc}} </mat-header-cell>
                <mat-cell *matCellDef="let recording">
                <audio-player *ngIf="recording.audioUrl != '' && recording.audioUrl != null" format="mini" [audioUrl]="recording.audioUrl" [trackIsrc]="recording.isrc" [trackName]="recording.title"></audio-player>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="isrc" >
                <mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by ISRC"> {{'recording.isrc'|loc}} </mat-header-cell>
                <mat-cell *matCellDef="let recording">
                <skeleton shape="small" *ngIf="recording.skeleton"></skeleton>
                <span *ngIf="!recording.skeleton">{{recording.isrc}}</span>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="title">
                <mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by title"> {{'recording.title'|loc}} </mat-header-cell>
                <mat-cell *matCellDef="let recording">
                <skeleton shape="autosm" *ngIf="recording.skeleton"></skeleton>
                <div *ngIf="!recording.skeleton">
                    <a [routerLink]="['/app-recording', recording.isrc]">{{recording.title}} </a>
                    <em class="comments">{{recording.subtitle}}</em>
                </div>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="year" >
                <mat-header-cell *matHeaderCellDef mat-sort-header>{{'recording.year'|loc}} </mat-header-cell>
                <mat-cell *matCellDef="let recording">
                <skeleton shape="small" *ngIf="recording.skeleton"></skeleton>
                <div *ngIf="!recording.skeleton">{{recording.year}}</div>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="artist" >
                <mat-header-cell *matHeaderCellDef mat-sort-header>
                {{'recording.main.artists'|loc}}
                </mat-header-cell>
                <mat-cell *matCellDef="let recording">
                        <skeleton shape="small"
                        *ngIf="recording.skeleton
                        || ((contributorsStatus$|async) === 'loading'
                                && recording['mainArtists'] === undefined
                                && recording['performers'] === undefined)"></skeleton>
                <div *ngIf="!recording.skeleton && recording['mainArtists'] !== undefined">
                    <div *ngFor=" let artist of recording.mainArtists" class="mainArtists">
                    <party-link [party]="artist" ></party-link>
                    </div>
                </div>
                </mat-cell>
            </ng-container>
    
            <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
            <mat-row matRipple *matRowDef="let client; columns: columns;" ></mat-row>
        </mat-table>
    </ng-container>
</ng-container>