<ng-container>
  <a *ngIf="identifier.type === 'quansicId'" [routerLink]="['/app-party', identifier.value]">
    <span #ref1><ng-content></ng-content></span>
    <span *ngIf="!ref1.innerHTML.trim()">{{text}}</span>
  </a>
  <a *ngIf="identifier.type !== 'quansicId'" [routerLink]="['/app-party', identifier.type, identifier.value]">
    <span #ref1><ng-content></ng-content></span>
    <span *ngIf="!ref1.innerHTML.trim()">{{text}}</span>
  </a>
</ng-container>
