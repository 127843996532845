import { Actions, createEffect, ofType } from "@ngrx/effects";
import { map, catchError, of, switchMap, tap } from "rxjs";
import { artistActions } from "./artist.actions";
import { recordingActions } from "../recording/recording.actions";
import { inject } from "@angular/core";
import { ApiSearchService } from "src/app/services/api-search.service";
import { Router } from "@angular/router";
import { ApiError } from "src/app/model";
import { ExplorerUtilsService } from "../../data/services/utils.service";
import { interpretError } from "../utils";

export class ArtistEffects {
  actions$ = inject(Actions)
  apiSearchService = inject(ApiSearchService);
  router = inject(Router);
  utilsService = inject(ExplorerUtilsService);

  getArtistByQuansicId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(artistActions.getartistbyquansicid),
      switchMap((action) =>
        this.apiSearchService.lookupArtistByQuansicId$(action.quansicId).pipe(
          map(({xParty, ySources}) => artistActions.getartistbyquansicidsuccess({xParty, ySources})),
          catchError(error => {
            this.utilsService.interceptAuthError(error);
            return of(artistActions.getartistbyquansicidfailure({ error: interpretError(error) }))
          }))
      ),
    )
  )

  resetRecordings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        artistActions.getartistsbyid,
        artistActions.getartistbyquansicid
      ),
      map(() => recordingActions.resetrecordings()),
    )
  )

  getArtistById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(artistActions.getartistsbyid),
      switchMap((action) =>
        this.apiSearchService.searchArtistsById$(action.idType, action.id).pipe(
          map((xParties) => {
            if(xParties.length > 1) {
              return artistActions.getartistsbyidmanysuccess({xParties, searchTerm: null});
            } else
            if(xParties.length === 1) {
              return artistActions.getartistsbyidsinglesuccess({xParty: xParties[0]})
            } else {
              return artistActions.getartistsbyidfailure({ error: ApiError.NOT_FOUND });
            }
          }),
          catchError(error => {
            this.utilsService.interceptAuthError(error);
            return of(artistActions.getartistsbyidfailure({ error: interpretError(error)}))
          }))
      ),
    )
  )

  getArtistByName$ = createEffect(() =>
    this.actions$.pipe(
      ofType(artistActions.getartistsbyname),
      switchMap((action) =>
        this.apiSearchService.searchArtistsByName$(action.name).pipe(
          map((xParties) => {
            if(xParties.length >= 1) {
              return artistActions.getartistsbyidmanysuccess({xParties, searchTerm: action.name});
            } else {
              return artistActions.getartistsbyidfailure({ error: ApiError.NO_MATCH });
            }
          }),
          catchError(error => {
            this.utilsService.interceptAuthError(error);
            return of(artistActions.getartistsbyidfailure({ error }))
          }))
      ),
    )
  )

  getartistsbyidmanysuccess = createEffect(() =>
    this.actions$.pipe(
      ofType(artistActions.getartistsbyidmanysuccess),
      tap((action) => this.router.navigate(['/app-disambiguation', action.searchTerm])),
    ),
    { dispatch: false }
  )

  getartistsbyidsinglesuccess = createEffect(() =>
    this.actions$.pipe(
      ofType(artistActions.getartistsbyidsinglesuccess),
      tap((action) => this.router.navigate(['/app-party/', action.xParty.ids.quansicId])),
    ),
    { dispatch: false }
  )


  getArtistNameVariants$ = createEffect(() =>
    this.actions$.pipe(
      ofType(artistActions.getnamevariants),
      switchMap((action) =>
        this.apiSearchService.lookupNameVariantsByQuansicId(action.quansicId).pipe(
          map(nameVariants => artistActions.getnamevariantssuccess({ nameVariants })),
          catchError(error => {
            this.utilsService.interceptAuthError(error);
            return of(artistActions.getnamevariantserror({ error }))
          }))
      ),
    )
  )

  getArtistRelationships$ = createEffect(() =>
    this.actions$.pipe(
      ofType(artistActions.getrelationships),
      switchMap((action) =>
        this.apiSearchService.lookupRelationshipsByQuansicId(action.quansicId).pipe(
          map(relationships => artistActions.getrelationshipssuccess({ relationships })),
          catchError(error => {
            this.utilsService.interceptAuthError(error);
            return of(artistActions.getrelationshipserror({ error }))
          }))
      ),
    )
  )
}
