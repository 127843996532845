import { HttpClient, HttpResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { catchError, EMPTY, iif, map, Observable, of, switchMap, take } from 'rxjs'
import { SpotifyService } from './spotify.service';
import { HttpHelperService } from './http.service';
@Injectable({
  providedIn: 'root'
})

export class ImageService {
  readonly http = inject(HttpClient);
  readonly httpHelper = inject(HttpHelperService);
  readonly spotifyService = inject(SpotifyService);

  wikimediaFileRegex = /http[s]?\:\/\/commons\.wikimedia\.org\/wiki\/File\:/;
  wikimediaFileRegexGroup = /http[s]?\:\/\/commons\.wikimedia\.org\/wiki\/File\:(.*)/;
  wikimediaImageResolver = 'https://commons.wikimedia.org/w/index.php?title=Special:Redirect/file'
  wikimediaImageWidth = 300;

  defaultArtistCover = '/assets/images/artist_default.jpg';

  isWikimediaImage(imagePath: string): boolean {
    return this.wikimediaFileRegex.test(imagePath);
  }

  convertImagePath$(imagePath: string|undefined, spotifyId: string|null): Observable<string> {
    if(imagePath === undefined) return EMPTY
    if(this.isWikimediaImage(imagePath)) {
      const wikimediaLink$ = of(imagePath).pipe(
        map(imagePath => this.convertWikimediaLink(imagePath)),
      )
      const spotifyImageLink = spotifyId ? this.spotifyService.getSpotifyImageLink(spotifyId) : of(this.defaultArtistCover);
      return wikimediaLink$
        .pipe(
          switchMap(wikimediaLink => this.isWikimediaLinkExists$(wikimediaLink)),
          switchMap(wikimediaLinkExists =>  iif(() => wikimediaLinkExists, wikimediaLink$, spotifyImageLink))
        )
    }
    
    else return of(imagePath)
  }

  private convertWikimediaLink(wikimediaPageLink: string) {
    let result = wikimediaPageLink;
    const matches = wikimediaPageLink.match(this.wikimediaFileRegexGroup);
    if(matches && matches.length > 1){
      result = `${this.wikimediaImageResolver}/${matches[1]}&width=${this.wikimediaImageWidth}`
    }
    return result
  }

  private isWikimediaLinkExists$(wikidataImageUrl: string): Observable<boolean> {
    return this.http.post(`${this.httpHelper.getBffDomain()}/api/wikidata/verify`, {wikidataImageUrl}, {observe: 'response'})
      .pipe(
        take(1),
        map((response) => (response as HttpResponse<any>).status === 200),
        catchError(() => of(false))
      )  
  }
}
