import { Component, OnInit, ViewChild, inject} from '@angular/core';
import { ApiError} from "../model";
import { ResultService} from "../services/result.service";
import { ActivatedRoute, Router} from "@angular/router";
import { TabsController} from "../_common/tabs.controller";
import { MatTabGroup } from '@angular/material/tabs';
import { MatDialog } from '@angular/material/dialog';
import { ExportService } from '../services/export.service';
import { ImageService } from '../services/image.service';
import { DialogCoverComponent } from '../dialog-cover/dialog-cover.component';
import { Store } from '@ngrx/store';
import { releaseActions } from '../shared/store/release/release.actions';
import { releaseQuery } from '../shared/store/release/release.selectors';
import { combineLatest, map, take, takeUntil, tap } from 'rxjs';
import { TitleService } from '../shared/data/services/title.service';
import { DialogUtilsService } from '../shared/data/services/dialog-utils.service';

@Component({
  selector: 'app-release',
  templateUrl: './app-release.component.html',
  styleUrls: ['./app-release.component.scss']
})
export class AppReleaseComponent extends TabsController implements OnInit {
  resultService = inject(ResultService);
  dialog = inject(MatDialog);
  exportService = inject(ExportService);
  imageService = inject(ImageService);
  store = inject(Store);
  titleService = inject(TitleService)
  dialogUtilsService = inject(DialogUtilsService)

  @ViewChild(MatTabGroup) matTabGroup!: MatTabGroup;

  defaultReleaseCover = 'assets/images/release_default.jpg';

  ApiError = ApiError;

  release$ = this.store.select(releaseQuery.selectRelease).pipe(
    tap(release => this.titleService.changeBrowserTitle('release', release)),
  )
  recordings$ = this.store.select(releaseQuery.selectRecordings);
  mainArtists$ = this.store.select(releaseQuery.selectMainArtists);

  releaseStatus$ = this.store.select(releaseQuery.selectStatus);
  releaseError$ = this.store.select(releaseQuery.selectError);
  searchParams$ = this.route.params.pipe(
    takeUntil(this.destroy$),
    map(params => (params['id']))
  )

  constructor(private router: Router, private route : ActivatedRoute) {
    super(router, route);
  }

  ngOnInit(): void {
    this.searchParams$.subscribe({
      next: (upc) => this.store.dispatch(releaseActions.getreleasebyupc({upc}))
    });
    this.readActiveTab()
  }

  openCoverDialog() {
    this.release$.pipe(take(1))
    .subscribe({
      next: (release) => {
        if(release?.cover === undefined) return
        this.dialog.open(DialogCoverComponent, {
          width: '45vw',
          data: {
            title: release.title,
            cover: release.cover,
            originalUrl: release.cover
          }
        })
      }
    })
  }

  exportRelease() {
    combineLatest([this.release$,this.recordings$,this.mainArtists$])
    .pipe(take(1))
    .subscribe({
      next: ([release, recordings, mainArtists]) => {
        if(release === null || recordings === null || mainArtists === null) return;
        this.exportService.exportReleaseAsExcel(release, recordings, mainArtists, release.title)
      }
    })
  }

  openFeedbackDialog() {
    this.dialogUtilsService.openFeedbackDialog(null);
  }
}



