<div class="background"></div>

<div class="container login two-col">
  <div class="left-block">
    <h1 class="hero">Data Explorer</h1>
    <h2>by <strong>QUANSIC</strong></h2>
  </div>
  <div class="right-block">
    <form [formGroup]="form">
      <div class="loginForm">
        <label style="flex-shrink: 1" for="email">Email</label>
        <mat-form-field appearance="fill" floatLabel="never"   style="flex-grow: 1">
          <input matInput formControlName="email" name="email" id="email">
          <mat-error *ngIf="isEmailInvalid()">The email is invalid</mat-error>
          <mat-error *ngIf="isEmailEmpty()">The email must be provided</mat-error>
        </mat-form-field>
        <label style="flex-shrink: 1" for="password">Password</label>
        <mat-form-field appearance="fill" floatLabel="never"   style="flex-grow: 1">
          <input matInput  name="password" formControlName="password" [type]="hide ? 'password' : 'text'" id="password">
          <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
            <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
          </button>
          <mat-error *ngIf="isPasswordEmpty()">The password must be provided</mat-error>
        </mat-form-field>
        <mat-spinner *ngIf="queryInProgress" diameter="40" color="accent" style="margin-left: 80px"></mat-spinner>
        <button mat-button mat-raised-button *ngIf="!queryInProgress" (click)="login()" [autofocus]="true" class="rounded-button" color="accent">Login</button>
        <mat-error *ngIf="isAuthenticationFailed()">Authentication Failed</mat-error>
      </div>
      <p style="margin-top: 20px">You have an API key and you dont' have a password yet ?
        <a class="link" routerLink="/app-login-key">Login with your Api Key</a><br><br>
        <em>(Api Key authentication will eventually be deprecated)</em>
      </p>
      <hr style="margin-top: 2rem"/>
      <p style="margin-top: 20px"><a class="link" routerLink="/app-register">Create your account</a></p>
      <p style="margin-top: 20px"><a class="link" routerLink="/app-recover">Reset your password</a></p>

    </form>

  </div>

  <div></div>
  <div>
    <app-stats></app-stats>
  </div>
</div>
