<div #container class="audio-player" [class]="format" >
  <button mat-icon-button (click)="toggleAudio()" class="transparent" [attr.aria-label]="isPlaying ? 'recording.audio.pause' : 'recording.audio.play' | loc">
    <audio #audioPlayer src="{{url}}" preload="metadata"></audio>
    <svg height="100%" width="100%">
      <circle fill="transparent"
            class="playback"
            [attr.stroke-dasharray]="playback.circumference + ' ' + playback.circumference"
            [attr.stroke-dashoffset]="playback.offset"
            [attr.stroke-width]="stroke"
            [attr.r]="playback.radius"
            cx="50%"
            cy="50%">
      </circle>
      <path d="M16 37.85v-28l22 14Z"  class="play" [ngClass]="{'hidden':isPlaying || isLoading}"  />
      <path d="M28.25 38V10H36v28ZM12 38V10h7.75v28Z" class="pause" [ngClass]="{'hidden':!isPlaying || isLoading}" />
    </svg>
  </button>
  <span>

  </span>
</div>
