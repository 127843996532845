
<div class="background"></div>

<div class="container login two-col">
  <div class="left-block">
    <h1 class="hero">Data Explorer</h1>
    <h2>by <strong>QUANSIC</strong></h2>
  </div>
  <div class="right-block">
    <form [formGroup]="form" *ngIf="!emailSent">
      <div class="loginForm">
          <label style="flex-shrink: 1" for="email">Email&nbsp;*</label>
          <mat-form-field appearance="fill" >
            <input matInput formControlName="email" type="email" required name="email" id="email">
            <mat-hint>Email is required</mat-hint>
          </mat-form-field>
      </div>
    </form>
    <div *ngIf="emailSent">
      <h3>The email has been successfully sent.</h3>
      <p>An email has been sent to the email address you provided with a link to reset your password.</p>

      <button mat-raised-button color="accent" (click)="navigateToLoginPage()" >Start Exploring</button>
    </div>
    <div style="display: flex; flex-direction: row; align-items: baseline; gap: 15px;"  *ngIf="!emailSent">
      <button mat-raised-button color="accent" (click)="sendRecoverPasswordEmail()" [autofocus]="true" style="width: 240px"
        [disabled]="!form.valid">
        <span *ngIf="!queryInProgress">Send Reset Password Email</span>
        <mat-spinner color="primary" *ngIf="queryInProgress" diameter="35" style="margin-inline: auto;"></mat-spinner>
      </button>
      <a class="link" routerLink="/app-login">Back to login form</a>
      <mat-error *ngIf="errorMessage !== null">{{errorMessage}}</mat-error>
    </div>
  </div>

  <div></div>
  <div></div>
</div>
