<ng-container *ngIf="recordingStatus$|async as status">

  <ng-container *ngIf="status === 'error'">
    <ng-container *ngIf="recordingError$|async as error">
      <ng-container *ngIf="searchParams$|async as isrc">
        <h2 *ngIf="(error === ApiError.INVALID_ID || error === ApiError.NOT_FOUND)">
          {{'recording.not.found.error'|loc}} (isrc = {{isrc}}).
          {{'error.verify.identifier'|loc}} <a href="javascript: return false" (click)="openFeedbackDialog()">{{'report.error'|loc}} </a>.
        </h2>
        <h2 *ngIf="error === ApiError.SERVER_ERROR || error === ApiError.UNKNOWN">
          {{'error.server.1'|loc}}
          {{'error.server.2'|loc}} <a href="javascript: return false" (click)="openFeedbackDialog()">{{'report.error'|loc}}</a>.
        </h2>
      </ng-container>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="status === 'idle' || status === 'loading'">
    <skeleton-recording [isCrtcUser]="crtcService.isCrtcUser()"></skeleton-recording>
  </ng-container>

  <ng-container *ngIf="status === 'success'">
    <ng-container *ngIf="recording$|async as recording">
      <div class="entity-page">
        <div class="name-block">
          <h1>{{'recording'|loc}}:</h1>
          <div class="name">
            <h1>{{recording.title}}</h1>
            <em class="comments">{{recording.subtitle}}</em>
          </div>
          <button mat-icon-button class="link export" (click)="exportRecording(recording)" [title]="'recording.download.data'|loc">
            <mat-icon [inline]="true">arrow_circle_down</mat-icon>
          </button>
          <!-- <button mat-icon-button class="link export" (click)="export()" title="Download artist data">
            <mat-icon [inline]="true">arrow_circle_down</mat-icon>
          </button> -->
        </div>
        <div class="info">
          <div class="picture">
            <audio-player *ngIf="recording.audioUrl != '' && recording.audioUrl != null else cover" format="full" [audioUrl]="recording.audioUrl" [trackIsrc]="recording.isrc" [trackName]="recording.title"></audio-player>
            <ng-template #cover>
              <img class="circle-mask circle-mask-large" src="{{'assets/images/recording_default.jpg'}}" [attr.alt]="'recording.default.cover'|loc"/>
            </ng-template>
          </div>
          <div style="flex: 0 1 1em"></div>
          <div class="information">
            <h2 loc="information"></h2>
            <div><span class="strong">{{'recording.duration'|loc}}:</span> {{recording.duration | duration}}</div>
            <div><span class="strong">{{'recording.year'|loc}}:</span> {{recording.year}}</div>
            <div><span class="strong">{{'recording.country'|loc}}:</span> {{recording.country}}</div>
            <div *ngIf="mainArtists$|async as mainArtists"><span class="strong">{{'recording.main.artists'|loc}}:</span>
              <span *ngFor="let artist of mainArtists | sortBy:'asc':'name'">
                <party-link [party]="artist" style="margin:0 5px"></party-link>
              </span>
            </div>
            <div>&#9413; {{recording.pLine}}</div>
          </div>
          <div class="identifiers">
            <h2 loc="identifiers"></h2>
            <div class="identifiers-list">
              <div class="identifier"><span class="strong">{{'recording.isrc'|loc}}:</span> {{recording.isrc}}</div>
              <div class="identifier">
                <span class="strong">{{'recording.spotifyTrackId'|loc}}:</span>  
                <a *ngIf="recording.spotifyId" href="https://open.spotify.com/track/{{recording.spotifyId}}" target="_blank">{{recording.spotifyId}}</a>
              </div>
              <div class="identifier"><span class="strong">{{'recording.appleTrackId'|loc}}:</span> {{recording.appleId}}</div>
            </div>
          </div>
          <div class="crtc" *ngIf="crtcService.isCrtcUser()">
            <h2>{{'recording.crtc'|loc}} <cancon-flag [isCancon]="recording.crtcMaiplStatus?.isCanadianContent"></cancon-flag></h2>
            <div><div class="strong">{{'recording.crtc.maipl.score'|loc}}: </div><maipl-score [score]="crtcMaiplScore$(recording)|async"></maipl-score></div>
            <div><div class="strong">{{'recording.crtc.is.cancon'|loc}}: </div>
              {{
                (recording.crtcMaiplStatus?.isCanadianContent ? 
                  'recording.crtc.is.cancon.yes' : 'recording.crtc.is.cancon.no') 
                  | loc
              }}</div>
            <div>
              <div class="strong"> {{'recording.crtc.language'|loc}}:  </div>
              <span *ngIf="recording.crtcPropertiesStatus.language as languageCode">
                {{getCrtcLanguageByCode(languageCode)?.name}}
              </span>
            </div>
            <div>
              <div class="strong"> {{'recording.crtc.sub.category'|loc}}: </div>
              <span *ngIf="recording.crtcPropertiesStatus.subCategory as subCategoryId">
                <ng-container *ngIf="getCrtcSubCategoryById(subCategoryId) as subCategory">
                  {{subCategory.subCategoryId}} - {{subCategory.label}} 
                </ng-container>
              </span>
            </div>
            <div>
              <div class="strong"> {{'recording.crtc.is.emerging'|loc}}: </div>
              <ng-container [ngSwitch]="recording.crtcPropertiesStatus.isEmerging">
                <span *ngSwitchCase="true">{{'recording.crtc.is.emerging.true'|loc}}</span>
                <span *ngSwitchCase="false">{{'recording.crtc.is.emerging.false'|loc}}</span>
                <span *ngSwitchDefault>{{'recording.crtc.is.emerging.not.set'|loc}}</span>
              </ng-container>
            </div>
            <div><button class="link" mat-button class="link" (click)="openCrtcDetailsDialog()">
              {{'recording.crtc.edit'|loc}}
            </button></div>
            <div><changelog [entityId]="recording.isrc"></changelog></div>
          </div>
        </div>
        <div class="lists">
          <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start" (selectedTabChange)="selectedTabChange($event)"
            [selectedIndex]="tabActive">
            <mat-tab [label]="'contributors'|loc">
              <div class="relationships" *ngIf="mainArtists$|async as mainArtists">
                <div class="title">{{'recording.main.artists'|loc}}</div>
                <div class="grid grid-four-col">
                  <party-link [party]="mainArtist" *ngFor="let mainArtist of mainArtists | sortBy:'asc':'name'"></party-link>
                </div>
                <hr class="grid-col-span-2"/>
              </div>
              <div class="relationships" *ngIf="performers$|async as performers">
                <div class="title">{{'recording.non.featured'|loc}}</div>
                <div class="grid grid-two-col">
                  <ng-container *ngFor="let performer of performers | sortBy:'asc':'name'">
                    <party-link [party]="performer"></party-link> ({{performer.role}})
                  </ng-container>
                </div>
              </div>
            </mat-tab>
            <mat-tab>
              <ng-template mat-tab-label>
                <div>{{'release'|loc}}</div>
                <div class="data-count">
                  (<span *ngIf="releases$|async as releases">{{releases.length}}</span>)
                </div>
              </ng-template>
              <table-releases [releases$]="releases$" ></table-releases>
            </mat-tab>
            <mat-tab>
              <ng-template mat-tab-label>
                <div>{{'works'|loc}}</div>
                <div class="data-count" *ngIf="tableWorksComponent">({{tableWorksComponent.dataSource.data.length}})</div>
              </ng-template>
              <table-works [works$]="works$" [q2]="true" [recordingId]="recording.isrc"></table-works>
            </mat-tab>
          </mat-tab-group>
        </div>
      </div>
    </ng-container>
  </ng-container>
</ng-container>
