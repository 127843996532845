<div class="mat-typography">
  <h1 mat-dialog-title>{{'dialog.feedback'|loc}}</h1>
  <div mat-dialog-content>
    <div *ngIf="!feedbackSent"  class="feedback">
      <h4 *ngIf="data" class="panel-context">
        <ng-container *ngIf="data.context === FeedbackContext.NOT_FOUND || data.context === FeedbackContext.IDENTIFIER">
          {{'dialog.feedback.search.identifier'|loc}}: {{data.notFound?.id}} ({{data.notFound?.idType}})
        </ng-container>
        <ng-container *ngIf="data.context === FeedbackContext.DISAMBIG">
          {{'dialog.feedback.search.name'|loc}}: {{data.disambig?.searchTerm}}
        </ng-container>
        <ng-container *ngIf="data.context === FeedbackContext.ARTIST">
          {{'dialog.feedback.selected.artist'|loc}}: {{data.artist?.name}}
        </ng-container>
        <ng-container *ngIf="data.context === FeedbackContext.RELEASE">
          {{'dialog.feedback.selected.release'|loc}}: {{data.release?.title}}
        </ng-container>
        <ng-container *ngIf="data.context === FeedbackContext.RECORDING">
          {{'dialog.feedback.selected.recording'|loc}}: {{data.recording?.title}}
        </ng-container>
        <ng-container *ngIf="data.context === FeedbackContext.WORK">
          {{'dialog.feedback.selected.work'|loc}} Work: {{data.work?.title}}
        </ng-container>
      </h4>
      <form [formGroup]="feedbackForm">
        <div class="two-col-grid">
          <label for="company">{{'dialog.contact.us.company'|loc}}<span class="text-danger">&nbsp;*</span></label>
          <mat-form-field appearance="fill">
            <input matInput aria-label="company" aria-describedby="company" formControlName="company" id="company"
              [ngClass]="{ 'is-invalid': isCompanyInvalid() }">
            <mat-error>{{'dialog.contact.us.company.error.required'|loc}}</mat-error>
          </mat-form-field>

          <label for="name">{{'dialog.contact.us.name'|loc}}<span class="text-danger">&nbsp;*</span></label>
          <mat-form-field appearance="fill">
            <input matInput aria-label="name" aria-describedby="name" formControlName="name" id="name"
              [ngClass]="{ 'is-invalid': isNameInvalid() }">
            <mat-error>{{'dialog.contact.us.name.error.required'|loc}}</mat-error>
          </mat-form-field>

          <label for="email">{{'dialog.contact.us.email'|loc}}<span class="text-danger">&nbsp;*</span></label>
          <mat-form-field appearance="fill">
            <input matInput aria-label="email" aria-describedby="email" formControlName="email" id="email"
              [ngClass]="{ 'is-invalid': isEmailInvalid() }">
            <mat-error *ngIf="isEmailInvalid() && isEmailEmpty()">{{'dialog.contact.us.email.error.required'|loc}}</mat-error>
            <mat-error *ngIf="isEmailInvalid() && isEmailFormatInvalid()">{{'dialog.contact.us.email.error.format'|loc}}</mat-error>
          </mat-form-field>

        <label for="purpose">{{'dialog.feedback.data'|loc}}</label>
          <mat-form-field appearance="fill">
            <mat-select formControlName="purpose" [disabled]="!feedback" class="regular" id="purpose">
              <mat-option *ngFor="let topic of feedback?.topics" [value]="topic.id">{{topic.locKey|loc}}</mat-option>
            </mat-select>
          </mat-form-field>

          <label class="col-form-label" for="message">{{'dialog.contact.us.message'|loc}}<span class="text-danger">&nbsp;*</span></label>
          <mat-form-field appearance="fill">
            <textarea matInput rows="6" formControlName="message" [placeholder]="msgPlaceholder" id="message"></textarea>
          </mat-form-field>
        </div>
      </form>
    </div>
    <div *ngIf="feedbackSent">
      <h4>{{'dialog.feedback.success.1'|loc}}</h4>
      <h4>{{'dialog.feedback.success.2'|loc}}</h4>
      <a class="link-info" (click)="reset()">{{'dialog.feedback.sedn.another'|loc}}</a>
    </div>
  </div>
  <div mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>{{'dialog.feedback.close'|loc}}</button>
    <button mat-raised-button color="accent" (click)="submit()" [disabled]="sending || feedbackSent">
      <span *ngIf="!sending">{{'dialog.feedback.send'|loc}}</span>
      <span *ngIf="sending">{{'dialog.feedback.sending'|loc}}</span>
    </button>
  </div>
</div>
