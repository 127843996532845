import { MatTableDataSource } from '@angular/material/table';
import { YDBService } from './../../../services/y-db.service';
import { ChangeLog } from './../../../services/inline-edit.service';
import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Observable, tap } from 'rxjs';

@Component({
  templateUrl: './dialog-changelog.component.html',
  styleUrls: ['./dialog-changelog.component.scss'],
})
export class DialogChangelogComponent {

  columns = ['property', 'originalValue', 'newValue', 'user', 'comment', 'date']

  datasource = new MatTableDataSource<ChangeLog>();
  changeLogs$: Observable<ChangeLog[]>

  constructor(
    public dialogRef: MatDialogRef<DialogChangelogComponent>,
    public ydbService: YDBService,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      this.changeLogs$ = this.ydbService.changeRequests$
      .pipe(
        tap(changeRequests => console.log("changerequestst", changeRequests)),
        tap(changeRequests => this.datasource.data = changeRequests)
      )
    }
}
