<form  class="search" [formGroup]="searchForm" (submit)="search()">
  <div class="full" *ngIf="format === 'full'">
    <div class="two-col">
      <div class="left">
        <h1 loc="home.text.title"></h1>

        <p style="margin-top: 80px;" loc="home.text.paragraph1"></p>
        <p loc="home.text.paragraph2"></p>
        <p loc="home.text.paragraph3"></p>
        <p loc="home.text.paragraph4"></p>
      </div>
      <div class="right">
        <div style="flex-grow: 1"></div>
        <div class="search-container ">
          <div class="search-input">
            <mat-form-field appearance="fill">
              <input matInput placeholder="" name="searchTerm" required formControlName="searchTerm" (keydown.enter)="search()" (keyup)="onChange()" (paste)="onChange()"
                aria-label="Search by artist name or identifier"/>
            </mat-form-field>
            <button mat-flat-button color="primary" class="btn-search large transparent" type="submit" aria-label="Search">
              <mat-icon alt="Arrow to the right">arrow_forward</mat-icon>
            </button>
          </div>

          <div class="tags-container">
            <div>
              <div class="tags" *ngFor="let criteriaGroup of searchCriteria | keyvalue">
                <div>{{criteriaGroup.key | loc}}</div>
                <ul>
                  <li *ngFor="let criteria of criteriaGroup.value">
                    <button (click)="setSearchType(criteria.id)" (keypress)="setSearchTypeKeyPress($event, criteria.id)" [ngClass]="{'active': searchType === criteria.id}" >{{criteria.label}}</button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div style="flex-grow: 1"></div>
      </div>
    </div>
  </div>

  <div class="mini" *ngIf="format === 'mini'">
      <mat-form-field appearance="outline" class="search-criteria">
        <mat-select formControlName="searchCriteria" name="Artists">
          <mat-optgroup>
            <mat-label>{{'artist'|loc}}</mat-label>
            <mat-option *ngFor="let crit of searchCriteria.get('artist')" [value]="crit.id">
              {{crit.label|loc}}
            </mat-option>
          </mat-optgroup>
          <mat-optgroup>
            <mat-label>{{'release'|loc}}</mat-label>
            <mat-option *ngFor="let crit of searchCriteria.get('release')" [value]="crit.id">
              {{crit.label|loc}}
            </mat-option>
          </mat-optgroup>
          <mat-optgroup>
            <mat-label>{{'recording'|loc}}</mat-label>
            <mat-option *ngFor="let crit of searchCriteria.get('recording')" [value]="crit.id">
              {{crit.label|loc}}
            </mat-option>
          </mat-optgroup>
          <mat-optgroup>
            <mat-label>{{'work'|loc}}</mat-label>
            <mat-option *ngFor="let crit of searchCriteria.get('work')" [value]="crit.id">
              {{crit.label|loc}}
            </mat-option>
          </mat-optgroup>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="fill" class="search-term">
        <input matInput name="searchTerm" required formControlName="searchTerm" (keydown.enter)="search()" (keyup)="onChange()" (paste)="onChange()"
          aria-label="Search by artist name or identifier"/>
      </mat-form-field>
      <button mat-flat-button color="primary" class="btn-search transparent" (click)="search()" aria-label="Search">
        <mat-icon alt="Arrow to the right">arrow_forward</mat-icon>
      </button>
  </div>
</form>

