<div class="recording">
    <h2>{{'recording'|loc}}: 
      <span style="font-weight: 300; margin-left: 10px">{{recording.id}}</span> 
      <span style="font-weight: 100; margin-left: 10px">({{recording.title}})</span>
    </h2>
    <div class="recording-table-container">
      <mat-table [dataSource]="recordingContribDS"
        *ngIf="recordingContribDS.data.length > 0; else noContributors" class="crtc-table">
        <ng-container matColumnDef="isni" >
          <mat-header-cell *matHeaderCellDef> {{'artist.isni'|loc}} </mat-header-cell>
          <mat-cell *matCellDef="let contributor">{{contributor.ids.isnis[0]}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="name" >
          <mat-header-cell *matHeaderCellDef> {{'recording.contributor'|loc}} </mat-header-cell>
          <mat-cell *matCellDef="let contributor">{{contributor.name}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="role" >
          <mat-header-cell *matHeaderCellDef> {{'recording.contributor.role'|loc}} </mat-header-cell>
          <mat-cell *matCellDef="let contributor">
            {{contributor.contributorType}}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="nationality" >
          <mat-header-cell *matHeaderCellDef> {{'recording.contributor.nationality'|loc}} </mat-header-cell>
          <mat-cell *matCellDef="let contributor">
            {{contributor.nationality}} <span *ngIf="contributor.nationalitySource">({{contributor.nationalitySource}})</span>
          </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
        <mat-row matRipple *matRowDef="let contributor; columns: columns;" ></mat-row>
      </mat-table>
    </div>
</div>
<ng-template #noContributors>
    {{'recording.contributor.no.contributor'|loc}}
  </ng-template>