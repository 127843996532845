import { filter, merge, takeUntil } from 'rxjs';
import { Observable, Subject, combineLatest, tap } from 'rxjs';
import { Component, Input, ViewChild, inject, OnDestroy, AfterViewInit, OnInit } from '@angular/core';
import { Recording, Artist} from "../../../model";
import { DurationPipe} from "../../../pipes/duration.pipe";
import { MatTableDataSource } from '@angular/material/table';
import { MatSort, Sort } from '@angular/material/sort';
import { TableControlsComponent } from '../table-controls/table-controls.component';

import {environment} from "../../../../environments/environment";
import { CrtcService } from '../../../crtc/services/crtc.service';
import { ReplacePipe } from '../../../pipes/replace.pipe';
import { Store } from '@ngrx/store';
import { recordingQuery } from '../../store/recording/recording.selectors';
import { Skeleton } from '../../store/state';
import { recordingActions } from '../../store/recording/recording.actions';
import { MatSnackBar, MatSnackBarRef} from '@angular/material/snack-bar';
import { LargeRecsMessageComponent } from './large-recs-message/large-recs-message.component';
import { workQuery } from '../../store/work/work.selectors';
import {remove as removeDiacritics} from 'diacritics';

@Component({
  selector: 'table-recordings',
  templateUrl: './table-recordings.component.html',
  styleUrls: ['./table-recordings.component.scss'],
  providers: [DurationPipe, ReplacePipe]
})
export class TableRecordingsComponent implements OnInit, AfterViewInit, OnDestroy {
  store = inject(Store<RecordingState>)
  crtcService = inject(CrtcService);  
  snackbar = inject(MatSnackBar);

  pageSize = environment.pageSize.recordings;

  @Input() quansicId! : string;
  @Input() recordings$! : Observable<Recording[]|null>;
  @Input() q2: boolean = false;

  @ViewChild(TableControlsComponent) tableControls!: TableControlsComponent;
  @ViewChild(MatSort) sort!: MatSort;

  destroy$ = new Subject<void>();
  recordingsDestroy$ = new Subject<void>();
  contributorsDestroy$ = new Subject<void>();

  recordingsLoadingInProgress = false;

  public dataSource = new MatTableDataSource<Recording>([]);
  columns = ['isrc', 'audio', 'title', 'year', 'artist'];

  offset = {
    recordings: 0,
    contributors: 0
  }

  status$ = merge(
    this.store.select(recordingQuery.selectStatus),
    this.store.select(workQuery.selectStatus)
  )
  contributorsStatus$ = this.store.select(recordingQuery.selectContributorsStatus);
  total: number = 0;

  recordingsProgress: number = 0;

  messageTooManyRecordingsDisplayed = false;
  msgTooManyRecsRef: MatSnackBarRef<LargeRecsMessageComponent>|null = null;

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
    this.recordingsDestroy$.next();
    this.recordingsDestroy$.complete();
    this.contributorsDestroy$.next();
    this.contributorsDestroy$.complete();
    this.store.dispatch(recordingActions.resetrecordings());
  }

  ngOnInit(): void {
    this.dataSource.filterPredicate = (data: Recording|Skeleton, filter: string) => {
      const recording = (data as Recording)
      let match = false;
      //title
      match = removeDiacritics(recording.title).toLowerCase().includes(removeDiacritics(filter).toLowerCase())
      if(match) return true
      //isrc
      if(recording.isrc) match = recording.isrc?.toLowerCase().includes(filter.toLowerCase())
      if(match) return true
      //year
      if(recording.year) match = recording.year.toString().includes(filter.toLowerCase())
      if(match) return true
      //contributors
      if(recording.mainArtists) recording.mainArtists.forEach(c => {
        if(removeDiacritics(c.name).toLowerCase().includes(removeDiacritics(filter).toLowerCase())) match = true;
      })
      if(match) return true
      else return false
    }
  }


  async ngAfterViewInit()  {
    this.tableControls.resetFilter();
    this.msgTooManyRecsRef = null;
    if(this.recordings$)
      this.recordings$.pipe(takeUntil(this.destroy$)).subscribe({
        next: recordings => {
          if(recordings !== null) this.dataSource.data = recordings
        }
      });

    if(this.quansicId ){
      combineLatest([
        this.store.select(recordingQuery.selectRecordings),
        this.store.select(recordingQuery.selectRecordingsTotal),
      ])
      .pipe(
        takeUntil(this.destroy$),
      )
      .subscribe({
        next: ([recordings, count]) => {
          if(count !== null) this.total = count;
          if(recordings !== null) {
            this.dataSource.data = recordings.filter(r => r !== null)
          }
        }
      })
      this.store.dispatch(recordingActions.getrecordingspagebyartistid({quansicId: this.quansicId, offset: 0, pageSize: this.pageSize}));
      
      this.listenForRecordingsPaginated();
      this.listenForRecordingContributorsPaginated();
    }

    this.tableControls.paginator$.subscribe((paginator) => {
      this.dataSource.paginator = paginator
    });

    this.tableControls.filter$.subscribe((filter) => {
      this.dataSource.filter = filter;
      this.tableControls.filteredDataCount = this.dataSource.filteredData.length;
    });

    if(this.q2 && environment.q2Score) this.columns.unshift('q2');
    // if(this.crtcService.isCrtcUser()) this.columns.unshift('crtc');
  }

  sortData(sort: Sort){
    if(!this.dataSource.sort){
      this.dataSource.sort = this.sort;
      this.dataSource.sort.sort({id: sort.active, start: sort.direction, disableClear: false});
    }
  }

  // listenForRecordingsCount() {
  //   this.store.select(recordingQuery.selectRecordingsTotal)
  //   .pipe(
  //     takeUntil(this.recordingsDestroy$),
  //     filter(total => total !== null),
  //     tap(total => {
  //       if(total !== null) this.total = total 
  //     }),
  //     tap(total => {
  //       this.listenForRecordingsPaginated();
  //       this.store.dispatch(recordingActions.getrecordingspagebyartistid({quansicId: this.quansicId, offset: 0, pageSize: this.pageSize}))
  //     })
  //   )
  //   .subscribe()
  //   this.store.dispatch(recordingActions.getrecordingscountbyartistid({quansicId: this.quansicId}))
  // }

  listenForRecordingsPaginated() {
    this.offset.recordings = 0;
  
    this.store.select(recordingQuery.selectRecordings)
    .pipe(
      takeUntil(this.recordingsDestroy$),
      tap((recordings) => {
        if(recordings === null || this.total === null) return;

        if(this.total >= environment.threshold.recordings && this.msgTooManyRecsRef === null) {
          this.msgTooManyRecsRef = this.snackbar.openFromComponent(LargeRecsMessageComponent, {
            verticalPosition: 'bottom',
            horizontalPosition: 'center',
            data: {recordingsCount: this.total}
          });
        }
        if(recordings.length > 0 && recordings.length < this.total){
          this.offset.recordings += environment.pageSize.recordings;
          this.recordingsProgress = (recordings.length / this.total) * 100;
          this.store.dispatch(recordingActions.getrecordingspagebyartistid({quansicId: this.quansicId, offset: this.offset.recordings, pageSize: this.pageSize}));
        }
        if(recordings.length >= (this.total - 1)){
          this.msgTooManyRecsRef?.dismiss();
          this.msgTooManyRecsRef = null;
          this.store.dispatch(recordingActions.getrecordingsbyartistidsuccess({quansicId: this.quansicId}));
          this.recordingsProgress = 100;
          this.recordingsDestroy$.next();
          this.recordingsDestroy$.complete();
          
        }
      })
    ).subscribe();
  }

  listenForRecordingContributorsPaginated() {
    this.offset.contributors = 0;
    this.store.select(recordingQuery.selectRecordingContributorsPage).pipe(
      takeUntil(this.contributorsDestroy$),
      tap((recordingContributorsPage) => {
        if(recordingContributorsPage === undefined || recordingContributorsPage === null) return;
        if(recordingContributorsPage?.length === 0) {
          this.store.dispatch(recordingActions.getrecordingcontributorspagebyartistidcomplete());
          this.contributorsDestroy$.next();
          this.contributorsDestroy$.complete();
        } else {
          recordingContributorsPage?.forEach(c => {
            const recIndex = this.dataSource.data.findIndex(r => r.isrc === c.isrc);
            this.dataSource.data[recIndex] = {
              ...this.dataSource.data[recIndex],
              mainArtists: c.mainArtists,
            }
          })
          this.dataSource.data = [...this.dataSource.data];
          this.offset.contributors += environment.pageSize.contributors;
          this.store.dispatch(recordingActions.getrecordingcontributorspagebyartistid({quansicId: this.quansicId, offset: this.offset.contributors, pageSize: environment.pageSize.contributors}));
        }
      })
    ).subscribe();
  }

  originalSortComparator!: (recordings: Recording[], sort: MatSort) =>  Recording[];
  initialLoad = true;

  getPerformersString(recording:Recording):string|undefined{
    return recording.performers?.map((p:Artist) => { return p.name + " (" + p.role + ")"}).join("|")
  }
  crtcMaiplScore$(recording: Recording) {
    return this.crtcService.maiplScoreStatus$(recording)
  }

  trackByIsrc(index: number, item: Recording|Skeleton) {
    return item.hasOwnProperty('isrc') ? (item as Recording).isrc : ''
  }
}
