<ng-container *ngIf="releaseStatus$|async as status">
  <ng-container *ngIf="status === 'error'">
    <ng-container *ngIf="releaseError$|async as error">
      <ng-container *ngIf="searchParams$|async as upc">
        <h2 *ngIf="(error === ApiError.INVALID_ID || error === ApiError.NOT_FOUND)">
          {{'release.not.found.error'|loc}} (upc = {{upc}}).
          {{'error.verify.identifier'|loc}} <a href="javascript: return false" (click)="openFeedbackDialog()"> {{'report.error'|loc}} </a>.
        </h2>
        <h2 *ngIf="error === ApiError.SERVER_ERROR || error === ApiError.UNKNOWN">
          {{'error.server.1'|loc}}
          {{'error.server.2'|loc}}<a href="javascript: return false" (click)="openFeedbackDialog()">{{'report.error'|loc}} </a>.
        </h2>
      </ng-container>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="status === 'idle' || status === 'loading'">
    <skeleton-release></skeleton-release>
  </ng-container>

  <ng-container *ngIf="status === 'success'">
    <ng-container *ngIf="release$|async as release">
      <div class="entity-page">
        <div class="name-block">
          <h1 loc="release"></h1>
          <div class="name">
            <h1>{{release.title}}</h1>
          </div>
          <button mat-icon-button class="link export" (click)="exportRelease()" [title]="'release.download.data'|loc">
            <mat-icon [inline]="true">arrow_circle_down</mat-icon>
          </button>
        </div>
        <div class="info">
          <div class="picture">
            <div *ngIf="release.cover !== undefined" (click)="openCoverDialog()" class="link" (keyDown)="openCoverDialog()">
              <img class="circle-mask circle-mask-large" [src]="release.cover" [attr.alt]="'release.cover.alt'|loc" />
            </div>
            <div *ngIf="release.cover === undefined" >
              <img class="circle-mask circle-mask-large" [src]="defaultReleaseCover" [attr.alt]="'release.default.cover.alt'|loc" />
            </div>
          </div>
          <div style="flex: 0 1 1em"></div>
          <div class="information">
            <h2 loc="information"></h2>
            <div><span class="strong" loc="release.type"></span>: {{release.type}}</div>
            <div><span class="strong" loc="release.year.of.recording"></span>: {{release.year}}</div>
            <div><span class="strong" loc="release.label"></span>: {{release.label}}</div>
          </div>
          <div class="identifiers">
            <h2 loc="identifiers"></h2>
            <div class="identifiers-list">
              <div class="identifier"><span class="strong" loc="release.upc"></span>: {{release.upc}}</div>
            </div>
          </div>
        </div>
        <div class="lists">
          <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start" (selectedTabChange)="selectedTabChange($event)"
          [selectedIndex]="tabActive">
            <mat-tab [label]="'contributors'|loc">
              <div class="relationships" *ngIf="mainArtists$|async as mainArtists">
                <div class="title" loc="release.main.artists"></div>
                <div class="grid grid-four-col">
                  <party-link [party]="artist" *ngFor="let artist of mainArtists | sortBy:'asc':'name'"></party-link>
                </div>
              </div>
            </mat-tab>
            <mat-tab>
              <ng-template mat-tab-label>
                <div loc="recordings"></div>
                <div class="data-count">
                  (
                    <ng-container>
                      <span *ngIf="recordings$|async as recordings">{{recordings.length}}</span>
                    </ng-container>
                  )
                </div>
                </ng-template>
              <table-recordings [recordings$]="recordings$"></table-recordings>
            </mat-tab>
          </mat-tab-group>
        </div>
      </div>
    </ng-container>
  </ng-container>
</ng-container>
