import packageJson from '../../package.json';
export const environment = {
  version: packageJson.version,
  demo: false,
  dataTest: false,
  production: true,
  apiBaseUrl: "origin",
  xPath: 'api/x',
  yPath: 'api/y',
  clientPath: 'api/client',
  emailPath: 'api/email',
  logPath: 'api/log',
  environmentName: 'dev',
  q2Score: true,
  pageSize: {
    recordings: 100,
    contributors: 100,
    works: 100
  },
  threshold: {
    recordings: 5000
  }
};
