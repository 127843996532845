import { ChangeRequestComponent } from './edit/change-request-component/change-request.component';
import { MaiplScoreComponent } from './crtc/maipl-score/maipl-score.component';
import { DialogChangelogComponent } from './edit/changelog/dialog-changelog/dialog-changelog.component';
import { DialogCrtcComponent } from './crtc/dialog-crtc/dialog-crtc.component';
import { AppLoginKeyComponent } from './login/app-login-key/app-login-key.component';
import { AppRecoverPasswordComponent } from './login/app-recover-password/app-recover-password.component';
import { TableControlsComponent } from './shared/components/table-controls/table-controls.component';
import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule} from '@angular/platform-browser/animations'
import { MatSelectCountryModule } from "@angular-material-extensions/select-country";

import { AuthGuard } from "./services/auth-guard";

import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import { IswcPipe } from './pipes/iswc.pipe';
import { IsrcPipe } from './pipes/isrc.pipe';
import { DurationPipe } from './pipes/duration.pipe';
import { TypeofPipe } from './pipes/typeof.pipe';
import { SortByPipe } from './pipes/sortBy.pipe';
import { ReplacePipe } from './pipes/replace.pipe';
import { SearchComponent } from './search/search.component';
import { AppLoginComponent } from './login/app-login/app-login.component';
import { AppHomeComponent } from './app-home/app-home.component';
import { AppArtistComponent } from './app-artist/app-artist.component';
import { AppReleaseComponent } from './app-release/app-release.component';
import { AppRecordingComponent } from './app-recording/app-recording.component';
import { AudioPlayerComponent } from './audio-player/audio-player.component';
import { PartyLinkComponent } from './party-link/party-link.component';
import { TableReleasesComponent } from './shared/components/table-releases/table-releases.component';
import { TableRecordingsComponent } from './shared/components/table-recordings/table-recordings.component';
import { IdentifierPillComponent } from './identifier-pill/identifier-pill.component';
import { DialogCoverComponent } from './dialog-cover/dialog-cover.component';
import { DialogRowsExportComponent } from './dialog-rows-export/dialog-rows-export.component';
import { DialogArtistExportComponent } from './dialog-artist-export/dialog-artist-export.component';
import { DialogContactComponent } from './dialog-contact/dialog-contact.component';
import { DialogHelpComponent } from './dialog-help/dialog-help.component';
import { DialogFeedbackComponent } from './dialog-feedback/dialog-feedback.component';
import { DialogLockComponent } from './artist-lock/dialog-lock/dialog-lock.component';
import { LockComponent } from './artist-lock/lock/lock.component';
import { DialogAdditionalIdsComponent } from './dialog-additional-ids/dialog-additional-ids.component';
import { PageErrorComponent } from './page-error/page-error.component';
import { NavbarComponent } from './navbar/navbar.component';
import { LoadingComponent } from './loading/loading.component';
import { AppDisambigComponent } from './app-disambig/app-disambig.component';
import { GraphComponent } from "./graph/graph.component";
import { TableWorksComponent } from './shared/components/table-works/table-works.component';
import { AppWorkComponent } from './app-work/app-work.component';
import { CopyToClipboardComponent } from './copy-to-clipboard/copy-to-clipboard.component';
import { AppRegisterComponent } from './login/app-register/app-register.component';
import { CounterComponent } from './counter/counter.component';
import { StatsComponent } from './stats/stats.component';
import { DialogTermsComponent } from './dialog-terms/dialog-terms.component';
import { DialogPolicyComponent } from './dialog-policy/dialog-policy.component';
import { ArtistIdentifierComponent } from './artist-identifier/artist-identifier.component';
import { TableEmptyComponent } from './shared/components/table-empty/table-empty.component';
import { ChangelogComponent } from './edit/changelog/changelog.component';
import { DialogConfirmComponent } from './edit/dialog-confirm/dialog-confirm.component';

import { MaterialModule } from './material.module';
import { MaiplEditPanelComponent } from './crtc/maipl-edit-panel/maipl-edit-panel.component';
import { GraphInstanceInterceptor } from './graph-instance.interceptor';
import { CanconFlagComponent } from './crtc/cancon-flag/cancon-flag.component';
import { RelationshipsComponent } from './app-artist/relationships/relationships.component';
import { SkeletonComponent } from './shared/components/skeleton/skeleton.component';

import { StoreModule } from '@ngrx/store';
import { artistFeature } from './shared/store/artist/artist.reducer';
import { EffectsModule } from '@ngrx/effects';
import { ArtistEffects } from './shared/store/artist/artist.effects';
import { NameVariantsComponent } from './app-artist/nameVariants/nameVariants.component';
import { WorkEffects } from './shared/store/work/work.effects';
import { workFeature } from './shared/store/work/work.reducer';
import { releaseFeature } from './shared/store/release/release.reducer';
import { ReleaseEffects } from './shared/store/release/release.effects';
import { RecordingEffects } from './shared/store/recording/recording.effects';
import { recordingFeature } from './shared/store/recording/recording.reducer';
import { DisambigItemComponent } from './app-disambig/disambig-item/disambig-item.component';
import { AppLandingComponent } from './app-landing/app-landing.component';
import { SkeletonArtistComponent } from './app-artist/skeleton-artist/skeleton-artist.component';
import { IdentifiersService } from './shared/data/services/identifiers.service';
import { SkeletonReleaseComponent } from './app-release/skeleton-release/skeleton-release.component';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { authFeature } from './shared/store/auth/auth.reducer';
import { AuthEffects } from './shared/store/auth/auth.effects';
import { SkeletonRecordingComponent } from './app-recording/skeleton-recording/skeleton-recording.component';
import { SkeletonWorkComponent } from './app-work/skeleton-work/skeleton-work.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { TableRecordingsSkeletonComponent } from './shared/components/table-recordings/table-recordings-skeleton/table-recordings-skeleton.component';
import { LargeRecsMessageComponent } from './shared/components/table-recordings/large-recs-message/large-recs-message.component';
import { MaiplScoreEditComponent } from './crtc/maipl-edit-panel/maipl-score-edit/maipl-score-edit.component';
import { MaiplPropertiesEditComponent } from './crtc/maipl-edit-panel/maipl-properties-edit/maipl-properties-edit.component';
import { RecordingContributorsComponent } from './crtc/maipl-edit-panel/recording-contributors/recording-contributors.component';
import { WorkContributorsComponent } from './crtc/maipl-edit-panel/work-contributors/work-contributors.component';
import { ArtistCoverComponent } from './app-artist/artist-cover/artist-cover.component';
import { lastValueFrom } from 'rxjs';
import { LocalizationService, SupportedLocale } from './shared/localization/localization.service';
import { environment } from 'src/environments/environment';
import { LocalizationDirective } from './shared/localization/localization.directive';
import { LocalizationPipe } from './shared/localization/localization.pipe';
import { LangSelectorComponent } from './navbar/lang-selector/lang-selector.component';

const initializeLocalization = (localizationService: LocalizationService): Function => {
  return (): Promise<SupportedLocale> => lastValueFrom(localizationService.loadDefaultLocale());
}

@NgModule({
  declarations: [
    AppComponent,
    AppHomeComponent,
    AppLoginComponent,
    AppLoginKeyComponent,
    AppRecoverPasswordComponent,
    AppArtistComponent,
    AppDisambigComponent,
    AppReleaseComponent,
    AppRecordingComponent,
    AppRegisterComponent,
    AppLandingComponent,
    IswcPipe,
    IsrcPipe,
    DurationPipe,
    SortByPipe,
    ReplacePipe,
    LocalizationPipe,
    SearchComponent,
    AudioPlayerComponent,
    PartyLinkComponent,
    TableControlsComponent,
    TableReleasesComponent,
    TableRecordingsComponent,
    TableWorksComponent,
    TableEmptyComponent,
    IdentifierPillComponent,
    DialogCoverComponent,
    DialogContactComponent,
    DialogTermsComponent,
    DialogPolicyComponent,
    DialogHelpComponent,
    DialogRowsExportComponent,
    DialogArtistExportComponent,
    DialogFeedbackComponent,
    DialogLockComponent,
    LockComponent,
    DialogAdditionalIdsComponent,
    DialogCrtcComponent,
    CanconFlagComponent,
    DialogChangelogComponent,
    PageErrorComponent,
    TypeofPipe,
    NavbarComponent,
    LoadingComponent,
    GraphComponent,
    AppWorkComponent,
    CopyToClipboardComponent,
    CounterComponent,
    StatsComponent,
    ArtistIdentifierComponent,
    ChangeRequestComponent,
    ChangelogComponent,
    DialogConfirmComponent,
    // InlineEditComponent,
    MaiplScoreComponent,
    MaiplScoreEditComponent,
    MaiplPropertiesEditComponent,
    MaiplEditPanelComponent,
    RecordingContributorsComponent,
    WorkContributorsComponent,
    RelationshipsComponent,
    NameVariantsComponent,
    SkeletonComponent,
    DisambigItemComponent,
    SkeletonArtistComponent,
    SkeletonReleaseComponent,
    SkeletonRecordingComponent,
    SkeletonWorkComponent,
    TableRecordingsSkeletonComponent,
    LargeRecsMessageComponent,
    ArtistCoverComponent,
    LangSelectorComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    // RecaptchaModule,
    // RecaptchaFormsModule,
    MaterialModule,
    MatSelectCountryModule.forRoot('en'),
    StoreModule.forRoot({
      auth: authFeature.reducer,
      artist: artistFeature.reducer,
      work: workFeature.reducer,
      release: releaseFeature.reducer,
      recording: recordingFeature.reducer
     }),
     StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      logOnly: environment.environmentName !== 'production', // Restrict extension to log-only mode
      autoPause: true, // Pauses recording actions and state changes when the extension window is not open
    }),
    EffectsModule.forRoot([AuthEffects, ArtistEffects, WorkEffects, ReleaseEffects, RecordingEffects]),
    MatProgressBarModule,
    LocalizationDirective,
  ],
  providers: [
    AuthGuard,
    DurationPipe,
    ReplacePipe,
    LocalizationPipe,
    IdentifiersService,
    { provide: HTTP_INTERCEPTORS, useClass: GraphInstanceInterceptor, multi: true },
    {
      provide: APP_INITIALIZER, 
      useFactory: initializeLocalization, 
      deps:[LocalizationService],
      multi: true
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

