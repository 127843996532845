import { Component, EventEmitter, inject, Input, Output } from "@angular/core";
import { Store } from "@ngrx/store";
import { filter, Observable, Subject, take, takeUntil, tap } from "rxjs";
import { Recording } from "src/app/model";
import { recordingQuery } from "../../store/recording/recording.selectors";
import { MatTableDataSource } from "@angular/material/table";
import { recordingActions } from "../../store/recording/recording.actions";
import { Skeleton } from "../../store/state";

@Component({
    selector: 'table-top-recordings',
    templateUrl: './table-top-recordings.component.html',
    styleUrls: ['../table-recordings/table-recordings.component.scss']
})
export class TableTopRecordingsComponent {
    store = inject(Store<RecordingState>)

    @Output() viewAll = new EventEmitter<void>();

    destroy$ = new Subject<void>();
    recordingsDestroy$ = new Subject<void>();

    status$ = this.store.select(recordingQuery.selectStatus);
    contributorsStatus$ = this.store.select(recordingQuery.selectContributorsStatus);
    recordingsTotal$ = this.store.select(recordingQuery.selectRecordingsTotal);
    
    public dataSource = new MatTableDataSource<Recording>([]);
    columns = ['isrc', 'audio', 'title', 'year', 'artist'];

    @Input() quansicId! : string;
    @Input() recordings$! : Observable<Recording[]|null>;

    ngOnInit(): void {
        if(this.quansicId ){
            this.store.dispatch(recordingActions.resetrecordings());
            this.store.select(recordingQuery.selectRecordings)
            .pipe(
                filter((recordings) => recordings !== null),
                take(1)
            ).subscribe({
                next: (recordings) => {
                    this.dataSource.data = recordings!.filter(r => r !== null);
                    this.store.dispatch(recordingActions.gettoprecordingscontributorsbyartistid({quansicId: this.quansicId}))
                    this.listenForRecordingContributors();
                }
            })
            this.store.dispatch(recordingActions.gettoprecordingsbyartistid({quansicId: this.quansicId}))
        }
    }

    trackByIsrc(index: number, item: Recording|Skeleton) {
        return item.hasOwnProperty('isrc') ? (item as Recording).isrc : ''
    }

    listenForRecordingContributors() {
        this.store.select(recordingQuery.selectRecordingContributorsPage).pipe(
            filter((recordingContributors) => recordingContributors !== null),
            take(1),
            tap((recordingContributors) => {
                if(recordingContributors === undefined || recordingContributors === null) return;
                recordingContributors?.forEach(c => {
                    const recIndex = this.dataSource.data.findIndex(r => r.isrc === c.isrc);
                    this.dataSource.data[recIndex] = {
                    ...this.dataSource.data[recIndex],
                    mainArtists: c.mainArtists,
                    }
                })
                this.dataSource.data = [...this.dataSource.data];
            })
        ).subscribe({next: () => {}});
    }

    viewAllRecordings() {
        this.viewAll.emit();
        this.store.dispatch(recordingActions.resetrecordings())
    }
}
