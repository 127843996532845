<ng-container *ngIf="workStatus$|async as status">
  <ng-container *ngIf="status === 'error'">
    <ng-container *ngIf="workError$|async as error">
      <ng-container *ngIf="searchParams$|async as id">
        <h2 *ngIf="(error === ApiError.INVALID_ID || error === ApiError.NOT_FOUND)">
          {{'work.not.found.error'|loc}} 
          <span *ngIf="identifiersService.isIswc(id)">(iswc = {{id}})</span>
          <span *ngIf="identifiersService.isBowi(id)">(bowi = {{id}})</span>.
          {{'error.verify.identifier'|loc}} <a href="javascript: return false" (click)="openFeedbackDialog()">{{'report.error'|loc}} </a>.
        </h2>
        <h2 *ngIf="error === ApiError.SERVER_ERROR || error === ApiError.UNKNOWN">
          {{'error.server.1'|loc}}
          {{'error.server.2'|loc}} <a href="javascript: return false" (click)="openFeedbackDialog()">{{'report.error'|loc}}</a>.
        </h2>
      </ng-container>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="status === 'idle' || status === 'loading'">
    <skeleton-work></skeleton-work>
  </ng-container>

  <ng-container *ngIf="status === 'success'">
    <ng-container *ngIf="work$|async as work">
      <div class="entity-page">
        <div class="name-block">
          <h1>{{'work'|loc}}:</h1>
          <div class="name">
            <h1>{{work.title}}</h1>
          </div>
          <button mat-icon-button class="link export" (click)="exportWork(work)" [title]="'work.export.title'|loc">
            <mat-icon [inline]="true">arrow_circle_down</mat-icon>
          </button>
        </div>
        <div class="info">
          <div class="picture">
            <img class="circle-mask circle-mask-large" src="{{'assets/images/work_default.jpg'}}" [attr.alt]="'work.default.cover'|loc"/>
          </div>
          <div style="flex: 0 1 1em"></div>
          <div class="identifiers">
            <h2>{{'identifiers'|loc}}</h2>
            <div class="identifiers-list">
              <div class="identifier"><span class="strong">{{'work.bowi'|loc}}:</span> {{work.bowi}}</div>
              <div class="identifier"><span class="strong">{{'work.iswc'|loc}}:</span> {{work.iswc}}</div>
            </div>
          </div>
        </div>
        <div class="lists">
          <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start" (selectedTabChange)="selectedTabChange($event)"
            [selectedIndex]="tabActive">
            <mat-tab [label]="'contributors'|loc">
              <ng-container *ngTemplateOutlet="contributors;context:{work: work}" ></ng-container>
            </mat-tab>
            <mat-tab>
              <ng-template mat-tab-label>
                <div>{{'recordings'|loc}}</div>
                <!-- <div class="data-count" *ngIf="tableRecordingsComponent">({{tableRecordingsComponent.dataSource.data.length}})</div> -->
              </ng-template>
              <table-recordings [recordings$]="recordings$" [q2]="true"></table-recordings>
            </mat-tab>
          </mat-tab-group>
        </div>
      </div>
    </ng-container>
  </ng-container>

  <ng-template #contributors let-work="work">
    <div class="relationships" *ngIf="work && work.contributors.length > 0" >
      <div class="title">{{'contributors'|loc}}</div>
      <div class="grid grid-two-col">
        <ng-container *ngFor="let artist of work.contributors | sortBy:'asc':'name'">
          <party-link [party]="artist" ></party-link> {{artist.role}}
        </ng-container>
      </div>
    </div>
  </ng-template>
</ng-container>
