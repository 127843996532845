  <div>
    <p *ngIf="changeRequests.length === 0">{{'recording.crtc.requests.no.requests'|loc}}.</p>

      <mat-table [dataSource]="changeRequests" *ngIf="changeRequests.length > 0">
        <!-- <ng-container matColumnDef="entityType" >
          <mat-header-cell *matHeaderCellDef>Entity</mat-header-cell>
          <mat-cell *matCellDef="let changeReq">{{changeReq.entityType}}</mat-cell>
        </ng-container> -->
        <ng-container matColumnDef="entityId" >
          <mat-header-cell *matHeaderCellDef>{{'recording.crtc.requests.id'|loc}}</mat-header-cell>
          <mat-cell *matCellDef="let changeReq">
            {{changeReq.entityName}}
            <br/>{{changeReq.displayId}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="property" >
          <mat-header-cell *matHeaderCellDef>{{'recording.crtc.requests.field'|loc}}</mat-header-cell>
          <mat-cell *matCellDef="let changeReq"> 
            <ng-container *ngIf="changeReq.property === 'isEmerging'">{{'recording.crtc.is.emerging'|loc}}</ng-container>
            <ng-container *ngIf="changeReq.property === 'language'">{{'recording.crtc.language'|loc}}</ng-container>
            <ng-container *ngIf="changeReq.property === 'subCategory'">{{'recording.crtc.sub.category'|loc}}</ng-container>
            <ng-container *ngIf="changeReq.property === 'isCanadianContent'">{{'recording.crtc.maipl.is.cancon'|loc}}</ng-container>
            <ng-container *ngIf="changeReq.property === 'crtcMaiplScore.music'">{{'recording.crtc.maipl.score.music'|loc}}</ng-container>
            <ng-container *ngIf="changeReq.property === 'crtcMaiplScore.artist'">{{'recording.crtc.maipl.score.artist'|loc}}</ng-container>
            <ng-container *ngIf="changeReq.property === 'crtcMaiplScore.instrument'">{{'recording.crtc.maipl.score.instrument'|loc}}</ng-container>
            <ng-container *ngIf="changeReq.property === 'crtcMaiplScore.lyrics'">{{'recording.crtc.maipl.score.lyrics'|loc}}</ng-container>
            <ng-container *ngIf="changeReq.property === 'crtcMaiplScore.performance'">{{'recording.crtc.maipl.score.performance'|loc}}</ng-container>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="originalValue" >
          <mat-header-cell *matHeaderCellDef>{{'recording.crtc.requests.field.old'|loc}}</mat-header-cell>
          <mat-cell *matCellDef="let changeReq">
            <span *ngIf="(changeReq.originalValue !== null && changeReq.originalValue !== undefined) else none">
              {{changeReq.originalValue}}
            </span>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="newValue" >
          <mat-header-cell *matHeaderCellDef>{{'recording.crtc.requests.field.new'|loc}}</mat-header-cell>
          <mat-cell *matCellDef="let changeReq">
            <span *ngIf="changeReq.newValue !== null else none">{{changeReq.newValue}}</span>
          </mat-cell>
        </ng-container>
        <!-- <ng-container matColumnDef="changedBy" >
          <mat-header-cell *matHeaderCellDef>Changed By</mat-header-cell>
          <mat-cell *matCellDef="let changeReq">
            <span>{{changeReq.user}}</span>
          </mat-cell>
        </ng-container> -->
        <ng-container matColumnDef="comment" >
          <mat-header-cell *matHeaderCellDef>{{'recording.crtc.requests.comments'|loc}}</mat-header-cell>
          <mat-cell *matCellDef="let changeReq; let i = index">
            <mat-form-field apparence="legacy">
              <textarea matInput [(ngModel)]="comments[i]"></textarea>
            </mat-form-field>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="delete">
          <mat-header-cell *matHeaderCellDef></mat-header-cell>
          <mat-cell *matCellDef="let changeReq">
            <button mat-icon-button (click)="discardChangeEvent(changeReq.uuid)" color="warn" [attr.alt]="'recording.crtc.requests.delete'|loc"><mat-icon>delete</mat-icon></button>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
        <mat-row matRipple *matRowDef="let changeReq; columns: columns;" ></mat-row>
      </mat-table >
  </div>
<ng-template #none><em>{{'recording.crtc.requests.none'|loc}}</em></ng-template>

