import { Component, Input, OnChanges } from '@angular/core';
import { environment } from './../../environments/environment';
import { ArtistService } from "../services/artist.service";
import { Artist } from "../model";
import { Router } from '@angular/router';

@Component({
  selector: 'party-link',
  templateUrl: './party-link.component.html',
  styleUrls: ['./party-link.component.css']
})
export class PartyLinkComponent implements OnChanges {

  @Input() party: Artist|null = null;
  isLink = true;

  identifier = {
    type: "",
    value: ""
  };
  text = "";

  constructor(private artistService: ArtistService, private router: Router) { }

  buildLink(idType: string, id: string): void{
    this.identifier.type = idType;
    this.identifier.value = this.artistService.formatIdRaw(idType, id);
    this.isLink = true
  }

  ngOnChanges(): void {
    if (this.party && this.party.ids) {
      this.text = this.party.name;
      const quansicId = this.party.ids.quansicId;
      this.identifier.type = "quansicId";
      this.identifier.value = quansicId;
    }
  }

  // navigate() {
  //   if(this.identifier.type === 'quansicId')
  //     this.router.navigate(['app-party', this.identifier.value])
  //   else this.router.navigate(['/app-party', this.identifier.type, this.identifier.value])
  // }
}
