import { Component, inject } from "@angular/core";
import { take, tap } from "rxjs";
import { LocalizationService, SupportedLocale } from "src/app/shared/localization/localization.service";

@Component({
    selector: 'lang-selector',
    template: `
        <div class="lang-selector" *ngIf="currentLocale$|async as currentLocale">
            <div *ngIf="currentLocale === SupportedLocale.EN">
                <span>English</span> 
                | 
                <button mat-flat-button class="link" (click)="loadLocale(SupportedLocale.FR_CA)">Français</button>
            </div>
            <div *ngIf="currentLocale === SupportedLocale.FR_CA">
                <button mat-flat-button class="link" (click)="loadLocale(SupportedLocale.EN)"> English </button> 
                | 
                <span>Français</span>
            </div>
        </div>
    `,
    styles: [`
        .lang-selector span {
            font-size: 14px;
            padding-inline: 16px
        }
    `]
})
export class LangSelectorComponent {
    readonly localizationService = inject(LocalizationService)

    currentLocale$ = this.localizationService.currentLocale$.asObservable()

    SupportedLocale = SupportedLocale

    loadLocale(locale: SupportedLocale) {
        console.log("switching to locale", locale)
        this.localizationService.loadLocale(locale).pipe(
            take(1),
            tap(locale => this.localizationService.saveCurrentLocale(locale)),
            tap(locale => console.log("locale loaded", locale)),
            tap(() => window.location.reload())
        ).subscribe()
    }
}
