<table-empty *ngIf="dataSource.data.length == 0" entity="Releases"></table-empty>

<div [ngClass]="{'hidden': dataSource.data.length == 0}">
  <table-controls [totalDataCount]="dataSource.data.length"></table-controls>
  <mat-table [dataSource]="dataSource" matSort (matSortChange)="sortData($event)">
    <ng-container matColumnDef="visual">
      <mat-header-cell *matHeaderCellDef> {{'release.visual'|loc}}  </mat-header-cell>
      <mat-cell *matCellDef="let release">
        <skeleton shape="tiny-circle" *ngIf="release.skeleton"></skeleton>
        <span *ngIf="!release.skeleton">
          <img *ngIf="release.cover !== undefined" [src]="release.cover" width="50" height="auto" loading="lazy" aria-hidden="true" alt="Release cover for {{release.name}}"/>
          <img *ngIf="release.cover === undefined" [src]="defaultReleaseCover" width="50" height="auto" loading="lazy" aria-hidden="true" alt="Default release cover for {{release.name}}"/>
        </span>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="type">
      <mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by type"> {{'release.type'|loc}} </mat-header-cell>
      <mat-cell *matCellDef="let release">
        <skeleton shape="small" *ngIf="release.skeleton"></skeleton>
        <span *ngIf="!release.skeleton">{{release.type}}</span>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="upc">
      <mat-header-cell *matHeaderCellDef mat-sort-header> {{'release.upc'|loc}} </mat-header-cell>
      <mat-cell *matCellDef="let release">
        <skeleton shape="small" *ngIf="release.skeleton"></skeleton>
        <span *ngIf="!release.skeleton">{{release.upc}}</span>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="title" >
      <mat-header-cell *matHeaderCellDef mat-sort-header> {{'release.title'|loc}} </mat-header-cell>
      <mat-cell *matCellDef="let release">
        <skeleton shape="small" *ngIf="release.skeleton"></skeleton>
        <span *ngIf="!release.skeleton">
          <a [routerLink]="['/app-release', release.upc]">{{release.title}}</a>
        </span>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="year" >
      <mat-header-cell *matHeaderCellDef mat-sort-header> {{'release.year'|loc}} </mat-header-cell>
      <mat-cell *matCellDef="let release"> {{release.year}} </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
    <mat-row matRipple *matRowDef="let release; columns: columns;" ></mat-row>
  </mat-table>
</div>
