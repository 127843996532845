<div class="properties" [formGroup]="formGroup">
    <div>
      <div>
        <mat-form-field style="width: 300px;" id="language">
          <mat-label>{{'recording.crtc.language.iso.6392'|loc}}</mat-label>
          <mat-select formControlName="language" >
            <mat-option [value]="lang.iso6392" *ngFor="let lang of languageList">{{lang.name}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field style="width: 300px;"  id="sub-category">
          <mat-label>{{'recording.crtc.sub.category'|loc}}</mat-label>
          <mat-select formControlName="subCategory">
            <mat-optgroup *ngFor="let category of categoriesList" [label]="'recording.crtc.sub.category.'+category.categoryId|loc">
              <mat-option *ngFor="let subCategory of category.subCategories;" [value]="subCategory.subCategoryId">
                {{subCategory.subCategoryId}} - {{'recording.crtc.sub.category.'+subCategory.subCategoryId | loc}}
              </mat-option>
            </mat-optgroup>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div>
        <mat-label>{{'recording.crtc.is.emerging'|loc}} ?<span *ngIf="isEmergingNotSet()">({{'recording.crtc.is.emerging.not.set'|loc}})</span></mat-label>
        <div class="isEmerging" *ngIf="!isEmergingNotSet()">
          <span style="cursor: pointer" (click)="setIsEmerging(false)">{{'recording.crtc.is.emerging.false'|loc}}</span>
          <mat-slide-toggle formControlName="isEmerging" color="accent"></mat-slide-toggle>
          <span style="cursor: pointer" (click)="setIsEmerging(true)">{{'recording.crtc.is.emerging.true'|loc}}</span>
        </div>
        <div *ngIf="isEmergingNotSet()">
          <a (click)="setIsEmerging(true)" href="javascript:;">{{'recording.crtc.is.emerging.set'|loc}}</a>
        </div>
      <span></span>
    </div>
  </div>