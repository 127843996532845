<form  class="search" [formGroup]="searchForm" (submit)="search()">
  <div class="full" *ngIf="format === 'full'">
    <div class="two-col">
      <div class="left">
        <h1>Global Music Repertoire</h1>

        <p style="margin-top: 80px;">Data Explorer is a 100% ID coverage searching tool powered by Quansic which allows  you to navigate through the global music repertoire</p>
        <p>You can connect all artists with their releases and recordings, whether they are main artists or non-featured artists and know the linked IDs such as ISNI, ISRC, UPC, IPI, IPN, etc</p>
        <p>Every month 10'000 new IDs are added to our platform. You can have access to Data Explorer via an API. To have more information you can contact our technical team who will be glad to help you.</p>
        <p>Enjoy your exploration</p>
      </div>
      <div class="right">
        <div style="flex-grow: 1"></div>
        <div class="search-container ">
          <div class="search-input">
            <mat-form-field appearance="fill">
              <input matInput placeholder="" name="searchTerm" required formControlName="searchTerm" (keydown.enter)="search()" (keyup)="onChange()" (paste)="onChange()"
                aria-label="Search by artist name or identifier"/>
            </mat-form-field>
            <button mat-flat-button color="primary" class="btn-search large transparent" type="submit" aria-label="Search">
              <mat-icon alt="Arrow to the right">arrow_forward</mat-icon>
            </button>
          </div>

          <div class="tags-container">
            <div>
              <div class="tags" *ngFor="let criteriaGroup of searchCriteria | keyvalue">
                <div>{{criteriaGroup.key | titlecase}}</div>
                <ul>
                  <li *ngFor="let criteria of criteriaGroup.value">
                    <button (click)="setSearchType(criteria.id)" (keypress)="setSearchTypeKeyPress($event, criteria.id)" [ngClass]="{'active': searchType === criteria.id}" >{{criteria.label}}</button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div style="flex-grow: 1"></div>
      </div>
    </div>
  </div>

  <div class="mini" *ngIf="format === 'mini'">
      <mat-form-field appearance="outline" class="search-criteria">
        <mat-select formControlName="searchCriteria" name="Artists">
          <mat-optgroup label="Artists">
            <mat-option *ngFor="let crit of searchCriteria.get('artists')" [value]="crit.id">
              {{crit.label}}
            </mat-option>
          </mat-optgroup>
          <mat-optgroup label="Releases">
            <mat-option *ngFor="let crit of searchCriteria.get('releases')" [value]="crit.id">
              {{crit.label}}
            </mat-option>
          </mat-optgroup>
          <mat-optgroup label="Recordings">
            <mat-option *ngFor="let crit of searchCriteria.get('recordings')" [value]="crit.id">
              {{crit.label}}
            </mat-option>
          </mat-optgroup>
          <mat-optgroup label="Works">
            <mat-option *ngFor="let crit of searchCriteria.get('works')" [value]="crit.id">
              {{crit.label}}
            </mat-option>
          </mat-optgroup>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="fill" class="search-term">
        <input matInput name="searchTerm" required formControlName="searchTerm" (keydown.enter)="search()" (keyup)="onChange()" (paste)="onChange()"
          aria-label="Search by artist name or identifier"/>
      </mat-form-field>
      <button mat-flat-button color="primary" class="btn-search transparent" (click)="search()" aria-label="Search">
        <mat-icon alt="Arrow to the right">arrow_forward</mat-icon>
      </button>
  </div>
</form>

