<div class="background"></div>

<div class="container login two-col">
  <div class="left-block">
    <h1 class="hero">{{'home.data.explorer'|loc}}</h1>
    <h2>{{'home.data.explorer.by'|loc}} <strong>{{'home.data.explorer.quansic.upper'|loc}}</strong></h2>
  </div>
  <div class="right-block">
    <form [formGroup]="form">
      <div class="loginForm">
        <label style="flex-shrink: 1" for="email" loc="login.email">Email</label>
        <mat-form-field appearance="fill" floatLabel="never"   style="flex-grow: 1">
          <input matInput formControlName="email" name="email" id="email">
          <mat-error *ngIf="isEmailInvalid()" loc="login.email.error.invalid">The email is invalid</mat-error>
          <mat-error *ngIf="isEmailEmpty()" loc="login.email.error.required">The email must be provided</mat-error>
        </mat-form-field>
        <label style="flex-shrink: 1" for="password" loc="login.password">Password</label>
        <mat-form-field appearance="fill" floatLabel="never"   style="flex-grow: 1">
          <input matInput  name="password" formControlName="password" [type]="hide ? 'password' : 'text'" id="password">
          <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
            <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
          </button>
          <mat-error *ngIf="isPasswordEmpty()" loc="login.password.error.required">The password must be provided</mat-error>
        </mat-form-field>
        <mat-spinner *ngIf="queryInProgress" diameter="40" color="accent" style="margin-left: 80px"></mat-spinner>
        <button mat-button mat-raised-button *ngIf="!queryInProgress" (click)="login()" [autofocus]="true" 
          class="rounded-button" color="accent" loc="login.button.label">
          Login
        </button>
        <mat-error *ngIf="isAuthenticationFailed()" loc="login.failed">Authentication Failed</mat-error>
      </div>
      <hr style="margin-top: 2rem"/>
      <p style="margin-top: 20px"><a class="link" routerLink="/app-register" loc="login.create.account">Create your account</a></p>
      <p style="margin-top: 20px"><a class="link" routerLink="/app-recover" loc="login.reset.password">Reset your password</a></p>

    </form>

  </div>

  <div></div>
  <div>
    <app-stats></app-stats>
  </div>
</div>
