<div class="mat-typography">
  <h1 mat-dialog-title>Report an error</h1>
  <div mat-dialog-content>
    <div *ngIf="!feedbackSent"  class="feedback">
      <h4 *ngIf="data" class="panel-context">
        <ng-container *ngIf="data.context === FeedbackContext.NOT_FOUND || data.context === FeedbackContext.IDENTIFIER">
          Search by Identifier: {{data.notFound?.id}} ({{data.notFound?.idType}})
        </ng-container>
        <ng-container *ngIf="data.context === FeedbackContext.DISAMBIG">
          Search by Name: {{data.disambig?.searchTerm}}
        </ng-container>
        <ng-container *ngIf="data.context === FeedbackContext.ARTIST">
          Selected Artist: {{data.artist?.name}}
        </ng-container>
        <ng-container *ngIf="data.context === FeedbackContext.RELEASE">
          Selected Release: {{data.release?.title}}
        </ng-container>
        <ng-container *ngIf="data.context === FeedbackContext.RECORDING">
          Selected Recording: {{data.recording?.title}}
        </ng-container>
        <ng-container *ngIf="data.context === FeedbackContext.WORK">
          Selected Work: {{data.work?.title}}
        </ng-container>
      </h4>
      <form [formGroup]="feedbackForm">
        <div class="two-col-grid">
          <label for="company">Company<span class="text-danger">&nbsp;*</span></label>
          <mat-form-field appearance="fill">
            <input matInput aria-label="company" aria-describedby="company" formControlName="company" id="company"
              [ngClass]="{ 'is-invalid': isCompanyInvalid() }">
            <mat-error>This field is required.</mat-error>
          </mat-form-field>

          <label for="name">Name<span class="text-danger">&nbsp;*</span></label>
          <mat-form-field appearance="fill">
            <input matInput aria-label="name" aria-describedby="name" formControlName="name" id="name"
              [ngClass]="{ 'is-invalid': isNameInvalid() }">
            <mat-error>This field is required.</mat-error>
          </mat-form-field>

          <label for="email">Email<span class="text-danger">&nbsp;*</span></label>
          <mat-form-field appearance="fill">
            <input matInput aria-label="email" aria-describedby="email" formControlName="email" id="email"
              [ngClass]="{ 'is-invalid': isEmailInvalid() }">
            <mat-error *ngIf="isEmailInvalid() && isEmailEmpty()">This field is required.</mat-error>
            <mat-error *ngIf="isEmailInvalid() && isEmailFormatInvalid()">This field must follow a standard email format.</mat-error>
          </mat-form-field>

        <label for="purpose">What data ?</label>
          <mat-form-field appearance="fill">
            <mat-select formControlName="purpose" [disabled]="!feedback" class="regular" id="purpose">
              <mat-option *ngFor="let topic of feedback?.topics" [value]="topic.id">{{topic.label}}</mat-option>
            </mat-select>
          </mat-form-field>

          <label class="col-form-label" for="message">Message<span class="text-danger">&nbsp;*</span></label>
          <mat-form-field appearance="fill">
            <textarea matInput rows="6" formControlName="message" [placeholder]="msgPlaceholder" id="message"></textarea>
          </mat-form-field>
        </div>
      </form>
    </div>
    <div *ngIf="feedbackSent">
      <h4>Thank you for your feedback!</h4>
      <h4>Your input is much appreciated and will be reviewed shortly by our team.</h4>
      <a class="link-info" (click)="reset()">Send another feedback</a>
    </div>
  </div>
  <div mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Close</button>
    <button mat-raised-button color="accent" (click)="submit()" [disabled]="sending || feedbackSent">
      <span *ngIf="!sending">Send</span>
      <span *ngIf="sending">Sending...</span>
    </button>
  </div>
</div>
