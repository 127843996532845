import { Component, inject, ViewEncapsulation } from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { combineLatest, map, Observable, Subject, takeUntil, tap } from "rxjs";
import { ConfirmDialog, ConfirmDialogData } from "src/app/edit/confirm-dialog/confirm-dialog.component";
import { ChangeRequest, InlineEditService } from "src/app/services/inline-edit.service";
import { Recording, Work } from "../../model";
import { Store } from "@ngrx/store";
import { recordingActions } from "src/app/shared/store/recording/recording.actions";
import { CrtcMaiplScore, MaiplCategory } from "../services/crtc-model";
import { CrtcService } from "../services/crtc.service";
import { MaiplScoreRulesService } from "../maipl-edit-panel/maipl-score-edit/rules/maipl-score-rules.service";
import { workActions } from "src/app/shared/store/work/work.actions";
import { workQuery } from "src/app/shared/store/work/work.selectors";
import { Skeleton } from "src/app/shared/store/state";
import { environment } from "src/environments/environment";




@Component({
  selector: 'dialog-crtc',
  templateUrl: './dialog-crtc.component.html',
  styleUrls: ['./dialog-crtc.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DialogCrtcComponent {
    readonly inlineEditService = inject(InlineEditService)
    readonly crtcService = inject(CrtcService)
    readonly maiplScoreRulesService = inject(MaiplScoreRulesService)
    readonly store = inject(Store)
    readonly dialog = inject(MatDialog)
    readonly dialogRef = inject(MatDialogRef<DialogCrtcComponent>)
    readonly data = inject(MAT_DIALOG_DATA);

    recording!: Recording
    // works: Work[] = []
    // recording$!: Observable<Recording>;
    works$!: Observable<Work[]>;
    crComments: any = {}

    editMode$ = this.inlineEditService.editMode$
    changeRequestsCount$ = this.inlineEditService.changeRequests$.pipe(
      map(crs => crs.length),
      map(count => (count > 0 ? count : null))
    )

    destroy$ = new Subject<void>();
    ngOnDestroy() {
      this.destroy$.next()
      this.destroy$.complete()
    }

    ngOnInit() {
      this.recording = this.data.recording;
      // if(this.data.works) {
      //   this.works = this.data.works;
      // }
    }

    commitAllChangeCandidates() {
      // crComments has been populated with the comments in the change-requests component
      this.inlineEditService.commitAllChangeCandidates({...this.crComments});
      this.pushRecordingChanges(this.recording);
      this.dialogRef.close()
    }

    pushRecordingChanges(recording: Recording) {
      const changeScore:CrtcMaiplScore = this.inlineEditService.changeRequests
        .filter(cr => cr.property.startsWith("crtcMaiplScore"))
        .map(cr => ({category: cr.property.split(".")[1] as MaiplCategory, value: cr.newValue}))
        .reduce((acc: CrtcMaiplScore, property: {category:MaiplCategory, value: string | boolean | string[] | null | undefined}) => {
          acc[property.category] = property.value as boolean
          return acc
        }, {} as CrtcMaiplScore)
      const existingScore = this.crtcService.getMaiplScore(recording.crtcMaiplStatus)
      const maiplScore = {...existingScore, ...changeScore}

      const changedIsCancon:boolean= this.inlineEditService.changeRequests.find(cr => cr.property.startsWith("isCanadianContent"))?.newValue as boolean
      const existingIsCancon:boolean = recording.crtcMaiplStatus!.isCanadianContent
      const isCancon:boolean = changedIsCancon !== null && changedIsCancon !== undefined ? changedIsCancon : existingIsCancon

      const changedLanguage:string|null = this.inlineEditService.changeRequests.find(cr => cr.property === "language")?.newValue as string || null
      const existingLanguage:string|null = recording.crtcPropertiesStatus.language || null
      const language:string|null = changedLanguage !== null ? changedLanguage : (existingLanguage || null)

      const changedSubCategory:string|null = this.inlineEditService.changeRequests.find(cr => cr.property === "subCategory")?.newValue as string || null
      const existingSubCategory:string|null = recording.crtcPropertiesStatus.subCategory || null
      const subCategory:string|null = changedSubCategory !== null ? changedSubCategory : (existingSubCategory || null)

      const changedIsEmerging:string|null = this.inlineEditService.changeRequests.find(cr => cr.property === "isEmerging")?.newValue as string || null
      const existingIsEmerging:boolean|null = recording.crtcPropertiesStatus.isEmerging !== undefined ? recording.crtcPropertiesStatus.isEmerging : null
      const isEmerging:boolean|null = changedIsEmerging !== null ? JSON.parse(changedIsEmerging) : existingIsEmerging
  
      this.store.dispatch(recordingActions.editrecording({
        recording: {
          ...recording,
          crtcMaiplStatus: {
            source: recording.crtcMaiplStatus?.source || 'Quansic',
            isCanadianContent: isCancon,
            classifications: {"Quansic": this.crtcService.getMaiplCode(maiplScore)}
          },
          crtcPropertiesStatus: {
            language,
            subCategory,
            isEmerging
          }
        }
      }))
    }

    discardAllChangeCandidates() {
      this.dialog.open(ConfirmDialog, {
        data: {
          title: 'Discard all change candidates',
          content: 'Are you sure you want to discard all change candidates ?',
          action: {
            label: 'Discard All Changes',
            callback: () => {
              this.inlineEditService.discardAllChangeCandidates()
              this.maiplScoreRulesService.resetOverrides()
              this.dialogRef.close()
            }
          }
        } as ConfirmDialogData
      })
    }

    cancelAndClose() {
      this.destroy$.next();
      if(this.inlineEditService.changeRequests.length > 0)
        this.discardAllChangeCandidates()
      else this.dialogRef.close()
    }
}


