import { ApiSearchService } from '../../../services/api-search.service';
import { EventEmitter, inject, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Component, Input, ViewChild, AfterViewInit } from '@angular/core';
import { Recording, Work, WorkOfRecording } from "../../../model";
import { MatTableDataSource } from '@angular/material/table';
import { TableControlsComponent } from '../table-controls/table-controls.component';
import { MatSort } from '@angular/material/sort';

import { Store } from '@ngrx/store';
import { WorkState } from '../../store/work/work.reducer';
import { workActions } from '../../store/work/work.actions';
import { workQuery } from '../../store/work/work.selectors';
import { combineLatest, EMPTY, Observable, tap } from 'rxjs';
import { skeleton, Skeleton } from '../../store/state';
import { environment } from 'src/environments/environment';
import {remove as removeDiacritics} from 'diacritics';

@Component({
  selector: 'table-works',
  templateUrl: './table-works.component.html',
  styleUrls: ['./table-works.component.scss']
})
export class TableWorksComponent implements AfterViewInit{
  store = inject(Store<WorkState>)
  @Input() quansicId! : string;
  @Input() recordingId!: string;
  @Input() works$! : Observable<Work[]|null>;
  @Input() q2:boolean = false;

  worksStatus$ = this.store.select(workQuery.selectStatus);

  public dataSource = new MatTableDataSource<Work|WorkOfRecording|Skeleton>([]);
  offset: number = 0;

  @ViewChild(TableControlsComponent) tableControls!: TableControlsComponent;
  @ViewChild(MatSort) sort!: MatSort;

  columns = ['bowi', 'iswc', 'title', 'artist'];

  ngOnInit(): void {
    this.dataSource.filterPredicate = (data: Work|Skeleton, filter: string) => {
      const work = (data as Work)
      let match = false;
      //title
      match = removeDiacritics(work.title).toLowerCase().includes(removeDiacritics(filter).toLowerCase())
      if(match) return true
      //iswc
      if(work.iswc) match = work.iswc?.toLowerCase().includes(filter.toLowerCase())
      if(match) return true
      //bowi
      if(work.bowi) match = work.bowi?.toLowerCase().includes(filter.toLowerCase())
      if(match) return true
      //contributors
      if(work.contributors) work.contributors.forEach(c => {
        if(removeDiacritics(c.name).toLowerCase().includes(removeDiacritics(filter).toLowerCase())) match = true;
      })
      if(match) return true
      else return false
    }
  }

  ngAfterViewInit() {
    if(this.works$)
      this.works$.subscribe({
        next: works => {
          if(works === null) this.dataSource.data = skeleton(4)
          else this.dataSource.data = works
          this.tableControls.resetFilter();
          if(this.recordingId) this.fetchPaginatedWorks(this.recordingId);
        }
    });

    if(this.quansicId){
      this.store.dispatch(workActions.getworksbyartistid({quansicId: this.quansicId}))
      this.store.select(workQuery.selectWorks).subscribe({
        next: (works) => {
          if(works)this.dataSource.data = works.filter(w => w !== null)
            this.tableControls.resetFilter();
        }
      })
    }

    this.dataSource.sort = this.sort;
    this.dataSource.sort.sort({id: 'title', start: 'asc', disableClear: false})

    this.tableControls.paginator$?.subscribe((paginator) => {
      this.dataSource.paginator = paginator
    });

    this.tableControls.filter$.subscribe((filter) => {
      this.dataSource.filter = filter;
      this.tableControls.filteredDataCount = this.dataSource.filteredData.length;
    });

    if(this.q2 && environment.q2Score) this.initQ2Score();
  }

  initQ2Score() {
    this.columns.unshift('q2');
  }

  fetchPaginatedWorks(isrc: string): void {
    this.offset = 0;
    this.store.dispatch(workActions.getworksbyrecordingid({isrc: isrc, offset: this.offset}));
    combineLatest([
      this.store.select(workQuery.selectWorkContributors),
      this.store.select(workQuery.selectWorkContributorsTotal)
    ]).pipe(
      tap(([workContributors, total]) => {
        this.dataSource.data = this.dataSource.data.map((work: Work|Skeleton) => {
          if(workContributors){
            const workId = (work as Work).iswc || (work as Work).bowi;
            const wc = workContributors.find(rc => rc.iswc === workId || rc.bowi === workId)
            if(wc) work = {...work, contributors: wc.contributors}
          }
          return work;
        })
        if(workContributors !== null && workContributors.length < total){
          this.offset += environment.pageSize.works;
          this.store.dispatch(workActions.getworksbyrecordingid({isrc: isrc, offset: this.offset}));
        }
      })
    ).subscribe();
  }
}
