
<div class="background"></div>

<div class="container login two-col">
  <div class="left-block">
    <h1 class="hero">{{'home.data.explorer'|loc}}</h1>
    <h2>{{'home.data.explorer.by'|loc}} <strong>{{'home.data.explorer.quansic.upper'|loc}}</strong></h2>
  </div>
  <div class="right-block">
    <form [formGroup]="form" *ngIf="!accountCreated">
      <div class="loginForm">
          <label for="email">{{'register.email'|loc}}&nbsp;*</label>
          <mat-form-field appearance="fill" >
            <input matInput formControlName="email" type="email" required name="email" id="email">
            <mat-hint>{{'register.email.hint'|loc}}</mat-hint>
            <!-- <mat-icon matSuffix [color]="isEmailEmpty() || isEmailInvalid() ? 'warn' : 'accent'">check_circle</mat-icon> -->
            <mat-error *ngIf="isEmailEmpty()">{{'register.email.error.required'|loc}}</mat-error>
            <mat-error *ngIf="isEmailInvalid()">{{'register.email.error.invalid'|loc}}</mat-error>
          </mat-form-field>

          <label style="flex-shrink: 1" for="password">{{'register.password'|loc}}&nbsp;*</label>
          <!-- <div style="margin-bottom: 15px"> -->
          <mat-form-field appearance="fill" >
            <input matInput formControlName="password" [type]="hide ? 'password' : 'text'" name="password" id="password">
            <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
              <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
            </button>
            <!-- <mat-icon matSuffix [color]="isPasswordInvalid() || isPasswordEmpty() ? 'warn' : 'accent'">check_circle</mat-icon> -->
            <mat-hint>{{'register.password.hint'|loc}}</mat-hint>
            <mat-error *ngIf="isPasswordEmpty()">{{'register.password.error.required'|loc}}</mat-error>
            <mat-error *ngIf="!isPasswordMinLength()">{{'register.password.error.length'|loc}}</mat-error>
            <mat-error *ngIf="!isPasswordOneUpperCase()">{{'register.password.error.uppercase'|loc}}</mat-error>
            <mat-error *ngIf="!isPasswordOneDigit()">{{'register.password.error.digit'|loc}}</mat-error>

          </mat-form-field>
            <!-- <div><mat-icon [color]="isPasswordMinLength() ? 'accent': ''">check_circle</mat-icon>Password is 8 characters long</div>
            <div><mat-icon [color]="isPasswordOneUpperCase() ? 'accent': ''">check_circle</mat-icon>Password is at least 1 upper case letter</div>
            <div><mat-icon [color]="isPasswordOneDigit() ? 'accent': ''">check_circle</mat-icon>Password is at least 1 digit</div> -->
          <!-- </div> -->

          <label style="flex-shrink: 1; align-self: center; line-height: normal;" for="confirmPassword">{{'register.confirm.password'|loc}}&nbsp;*</label>
          <div>
            <mat-form-field appearance="fill" >
              <input matInput formControlName="confirmPassword" [type]="hide ? 'password' : 'text'" name="confirmPassword" id="confirmPassword">
              <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
              </button>
              <!-- <mat-icon matSuffix [color]="isPasswordConfirmNoMatch() ? 'warn' : 'accent'">check_circle</mat-icon> -->
            </mat-form-field>
            <mat-error *ngIf="isPasswordConfirmNoMatch()">{{'register.confirm.password.error.confirm'|loc}}</mat-error>
          </div>

          <label style="flex-shrink: 1" for="name">{{'register.name'|loc}}&nbsp;*</label>
          <mat-form-field appearance="fill" >
            <input matInput formControlName="name" required name="name" id="name">
            <mat-hint>{{'register.name.hint'|loc}}</mat-hint>
            <mat-error>{{'register.name.error.required'|loc}}</mat-error>
          </mat-form-field>

          <label style="flex-shrink: 1" for="company">{{'register.company'|loc}}&nbsp;*</label>
          <mat-form-field appearance="fill">
            <input matInput formControlName="company" name="company" id="company">
            <mat-hint>{{'register.company.hint'|loc}}</mat-hint>
            <mat-error>{{'register.company.error.required'|loc}}</mat-error>
          </mat-form-field>

          <div style="grid-column: span 2;font-size: 0.7em;">
            <mat-checkbox formControlName="agree">
              {{'register.agree.1'|loc}}
              <a mat-button class="link" (click)="openTermsConditionsDialog()">{{'register.agree.terms.conditions'|loc}}</a>
              {{'register.agree.2'|loc}}<a  mat-button class="link" (click)="openPrivacyPolicyDialog()">{{'register.agree.privacy.policy'|loc}}</a>
              <mat-error *ngIf="isAgreeUnchecked() && submitted">{{'register.agree.error'|loc}}</mat-error>
            </mat-checkbox>
          </div>
      </div>
    </form>
    <div *ngIf="accountCreated">
      <h3>{{'register.success'|loc}}</h3>
      <button mat-raised-button color="accent" (click)="navigateToLoginPage()">{{'register.start.exploring'|loc}}</button>
    </div>
    <div style="display: flex; flex-direction: row; align-items: baseline; gap: 15px;"  *ngIf="!accountCreated">
      <button mat-raised-button color="accent" (click)="register()" [autofocus]="true" style="width: 240px"
        [disabled]="!form.valid">
        <span *ngIf="!queryInProgress">{{'register.account'|loc}}</span>
        <mat-spinner color="primary" *ngIf="queryInProgress" diameter="35" style="margin-inline: auto;"></mat-spinner>
      </button>
      <a class="link" routerLink="/app-login">{{'register.back.login'|loc}}</a>
      <mat-error *ngIf="errorMessage !== null">{{errorMessage}}</mat-error>
    </div>
    <p *ngIf="errorMessage !== null" style="margin-top: 20px">{{'register.forgot.password'|loc}}<a class="link" routerLink="/app-recover">{{'register.recover.password'|loc}}</a></p>
  </div>
</div>
