import { TableControlsComponent } from '../table-controls/table-controls.component';
import { ChangeDetectionStrategy, Component, Input, ViewChild, AfterViewInit, OnInit, inject, SimpleChanges } from '@angular/core';
import { Release } from "../../../model";
import { ExportService } from "../../../services/export.service";
import { MatTableDataSource } from '@angular/material/table';
import { MatSort, Sort } from '@angular/material/sort';
import { Observable, tap } from 'rxjs';
import { Skeleton, skeleton } from '../../store/state';
import { Store } from '@ngrx/store';
import { ReleaseState } from '../../store/release/release.reducer';
import { releaseQuery } from '../../store/release/release.selectors';
import { releaseActions } from '../../store/release/release.actions';
import {remove as removeDiacritics} from 'diacritics';

@Component({
  selector: 'table-releases',
  templateUrl: './table-releases.component.html',
  styleUrls: ['./table-releases.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableReleasesComponent implements OnInit, AfterViewInit {
  store = inject(Store<ReleaseState>)

  @Input() quansicId! : string;
  @Input() releases$! : Observable<Release[]|null>;

  @ViewChild(TableControlsComponent) tableControls!: TableControlsComponent;
  @ViewChild(MatSort) sort!: MatSort;

  defaultReleaseCover = 'assets/images/release_default.jpg';

  public dataSource = new MatTableDataSource<Release|Skeleton>([]);
  columns = ['visual', 'type', 'upc', 'title', 'year'];

  constructor(private exportService: ExportService) { }

  ngOnInit(): void {
    this.dataSource.filterPredicate = (data: Release|Skeleton, filter: string) => {
      const release = (data as Release)
      let match = false;
      //title
      match = removeDiacritics(release.title).toLowerCase().includes(removeDiacritics(filter).toLowerCase())
      if(match) return true
      //upc
      if(release.upc) match = release.upc?.toLowerCase().includes(filter.toLowerCase())
      if(match) return true
      //year
      if(release.year) match = release.year.toLowerCase().includes(filter.toLowerCase())
      if(match) return true
      else return false
    }
  }

  ngAfterViewInit() {
    if(this.releases$)
      this.releases$.subscribe({
        next: recordings => {
          if(recordings === null) this.dataSource.data = skeleton(4)
          else this.dataSource.data = recordings
          this.tableControls.resetFilter();
        }
      })

    if(this.quansicId){
      this.store.dispatch(releaseActions.getreleasesbyartistid({quansicId: this.quansicId}))
      this.store.select(releaseQuery.selectReleases).subscribe({
        next: (releases) =>  {
          if(releases !== null) this.dataSource.data = releases
          this.tableControls.resetFilter();
        }
      })
    }

    this.tableControls?.paginator$.subscribe((paginator) => {
      this.dataSource.paginator = paginator
    });

    this.tableControls?.filter$.subscribe((filter) => {
      this.dataSource.filter = filter;
      this.tableControls.filteredDataCount = this.dataSource.filteredData.length;
    });
  }

  sortData(sort: Sort){
    if(!this.dataSource.sort){
      this.dataSource.sort = this.sort;
      this.dataSource.sort.sort({id: sort.active, start: sort.direction, disableClear: false});
    }
  }

  // intialSort() {
  //   let data = [...this.dataSource.data];
  //   this.dataSource.data = data.sort((a: Release, b: Release) => {
  //     const aYear = a.year ? parseInt(a.year): 0;
  //     const bYear = b.year ? parseInt(b.year) : 0;
  //     const aType = a.type && a.type === 'Album' ? 1: 0;
  //     const bType = b.type && b.type === 'Album' ? 1: 0;

  //     const aCover = a.cover ? 1: 0;
  //     const bCover = b.cover ? 1 : 0;
  //     return  bType - aType || bCover - aCover || bYear - aYear || a.title.localeCompare(b.title)
  //   });
  // }

  // loadReleases(): void {
  //   this.dataSource.data = this.releases
  //   this.tableControls.filteredDataCount = this.dataSource.data.length;
  //   this.tableControls.totalDataCount = this.dataSource.data.length;
  //   if(this.dataSource.paginator) this.dataSource.paginator.firstPage();

  //   this.intialSort();
  // }

  // exportExcel(): any{
  //   const lines = this.dataSource.filteredData
  //   .filter(row => row)
  //   .map((row)=> {
  //     return {
  //       upc: row.upc,
  //       title: row.title,
  //       year: row.year,
  //       label: row.label
  //     }
  //   })
  //   this.exportService.exportReleasesAsExcel(this.name, this.dataSource.filteredData);
  // }

  // exportCSV(): void{
  //   this.exportService.exportReleasesAsCSV(this.name, this.dataSource.filteredData);
  // }

}
