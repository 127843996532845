import { createActionGroup, emptyProps, props} from '@ngrx/store'
import { Artist, Recording, RecordingContributor, Release, Work, XAPIParty, XAPIRecording, XAPIRelease, XAPIWork, YSources } from 'src/app/model';

export const recordingActions = createActionGroup({
  source: 'Recording',
  events: {
    getRecordingByIsrc: props<{isrc: string}>(),
    getRecordingByIsrcSuccess: props<{
      xRecording: XAPIRecording,
      ySources: YSources
    }>(),
    getRecordingByIsrcError: props<{error: string}>(),

    getRecordingsByArtistId: props<{quansicId: string}>(),
    // getRecordingsByArtistIdSuccess: props<{xrecordings:XAPIRecording[], quansicId: string}>(),
    getRecordingsByArtistIdSuccess: props<{quansicId: string}>(),
    getRecordingsByArtistIdError: props<{error: string}>(),

    getRecordingsCountByArtistId: props<{quansicId: string}>(),
    getRecordingsCountByArtistIdSuccess: props<{total: number}>(),
    getRecordingsCountByArtistIdError: props<{error: string}>(),

    getTopRecordingsByArtistId: props<{quansicId: string}>(),
    getTopRecordingsByArtistIdSuccess: props<{recordings: Recording[]}>(),
    getTopRecordingsByArtistIdError: props<{error: string}>(),
    getTopRecordingsContributorsByArtistId: props<{quansicId: string}>(),
    getTopRecordingsContributorsByArtistIdSuccess: props<{contributors: RecordingContributor[]}>(),
    getTopRecordingsContributorsByArtistIdError: props<{error: string}>(),

    getRecordingsPageByArtistId: props<{quansicId: string, offset: number, pageSize: number}>(),
    getRecordingsPageByArtistIdSuccess: props<{recordings: Recording[]}>(),
    getRecordingsPageByArtistIdError: props<{error: string}>(),
    getRecordingsPageByArtistIdComplete: props<{quansicId: string}>(),

    getRecordingContributorsByArtistId: emptyProps(),
    getRecordingContributorsPageByArtistId: props<{quansicId: string, offset: number, pageSize: number}>(),
    getRecordingContributorsPageByArtistIdSuccess: props<{contributors: RecordingContributor[]}>(),
    getRecordingContributorsPageByArtistIdError: props<{error: string}>(),
    getRecordingContributorsPageByArtistIdComplete: emptyProps(),

    getRecordingsByReleaseId: props<{isrc: string}>(),
    getRecordingsByReleaseIdSuccess: props<{recordings:Recording[]}>(),
    getRecordingsByReleaseIdError: props<{error: string}>(),

    editRecording: props<{recording: Recording}>(),
    resetRecordings: emptyProps(),
    resetRecordingCount: emptyProps(),
  }
})
