
<div class="background"></div>

<div class="container login two-col">
  <div class="left-block">
    <h1 class="hero">Data Explorer</h1>
    <h2>by <strong>QUANSIC</strong></h2>

  </div>
  <div class="right-block">
    <form [formGroup]="form" *ngIf="!accountCreated">
      <div class="loginForm">
          <label for="email">Email&nbsp;*</label>
          <mat-form-field appearance="fill" >
            <input matInput formControlName="email" type="email" required name="email" id="email">
            <mat-hint>Email is required</mat-hint>
            <!-- <mat-icon matSuffix [color]="isEmailEmpty() || isEmailInvalid() ? 'warn' : 'accent'">check_circle</mat-icon> -->
            <mat-error *ngIf="isEmailEmpty()">The email is mandatory</mat-error>
            <mat-error *ngIf="isEmailInvalid()">The email does not have the correct format.</mat-error>
          </mat-form-field>

          <label style="flex-shrink: 1" for="password">Password&nbsp;*</label>
          <!-- <div style="margin-bottom: 15px"> -->
          <mat-form-field appearance="fill" >
            <input matInput formControlName="password" [type]="hide ? 'password' : 'text'" name="password" id="password">
            <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
              <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
            </button>
            <!-- <mat-icon matSuffix [color]="isPasswordInvalid() || isPasswordEmpty() ? 'warn' : 'accent'">check_circle</mat-icon> -->
            <mat-hint>Password must be at least 8 characters long, have at least one digit and at least one uppercase letter</mat-hint>
            <mat-error *ngIf="isPasswordEmpty()">The password is mandatory</mat-error>
            <mat-error *ngIf="!isPasswordMinLength()">The password must be 8 characters long</mat-error>
            <mat-error *ngIf="!isPasswordOneUpperCase()">The password must have at least one upper case letter</mat-error>
            <mat-error *ngIf="!isPasswordOneDigit()">The password must have at least one digit</mat-error>

          </mat-form-field>
            <!-- <div><mat-icon [color]="isPasswordMinLength() ? 'accent': ''">check_circle</mat-icon>Password is 8 characters long</div>
            <div><mat-icon [color]="isPasswordOneUpperCase() ? 'accent': ''">check_circle</mat-icon>Password is at least 1 upper case letter</div>
            <div><mat-icon [color]="isPasswordOneDigit() ? 'accent': ''">check_circle</mat-icon>Password is at least 1 digit</div> -->
          <!-- </div> -->

          <label style="flex-shrink: 1; align-self: center; line-height: normal;" for="confirmPassword">Confirm Password&nbsp;*</label>
          <div>
            <mat-form-field appearance="fill" >
              <input matInput formControlName="confirmPassword" [type]="hide ? 'password' : 'text'" name="confirmPassword" id="confirmPassword">
              <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
              </button>
              <!-- <mat-icon matSuffix [color]="isPasswordConfirmNoMatch() ? 'warn' : 'accent'">check_circle</mat-icon> -->
            </mat-form-field>
            <mat-error *ngIf="isPasswordConfirmNoMatch()">Passwords must match</mat-error>
          </div>

          <label style="flex-shrink: 1" for="name">Name&nbsp;*</label>
          <mat-form-field appearance="fill" >
            <input matInput formControlName="name" required name="name" id="name">
            <mat-hint>Name is required</mat-hint>
            <mat-error>Name is required</mat-error>
          </mat-form-field>

          <label style="flex-shrink: 1" for="company">Company&nbsp;*</label>
          <mat-form-field appearance="fill">
            <input matInput formControlName="company" name="company" id="company">
            <mat-hint>Company is required</mat-hint>
            <mat-error>Company is required</mat-error>
          </mat-form-field>

          <div style="grid-column: span 2;">
            <mat-checkbox formControlName="agree">
              By checking this box, you agree with the
              <a mat-button class="link" (click)="openTermsConditionsDialog()">Terms and Conditions</a>
              and with <a  mat-button class="link" (click)="openPrivacyPolicyDialog()">Quansic's Privacy Policy</a>
              <mat-error *ngIf="isAgreeUnchecked() && submitted">The Terms and Conditions and Privacy Policy must be accepted.</mat-error>
            </mat-checkbox>
          </div>
      </div>
    </form>
    <div *ngIf="accountCreated">
      <h3>The account has been successfully created.</h3>

      <button mat-raised-button color="accent" (click)="navigateToLoginPage()" >Start Exploring</button>
    </div>
    <div style="display: flex; flex-direction: row; align-items: baseline; gap: 15px;"  *ngIf="!accountCreated">
      <button mat-raised-button color="accent" (click)="register()" [autofocus]="true" style="width: 240px"
        [disabled]="!form.valid">
        <span *ngIf="!queryInProgress">Register Account</span>
        <mat-spinner color="primary" *ngIf="queryInProgress" diameter="35" style="margin-inline: auto;"></mat-spinner>
      </button>
      <a class="link" routerLink="/app-login">Back to login form</a>
      <mat-error *ngIf="errorMessage !== null">{{errorMessage}}</mat-error>
    </div>
    <p *ngIf="errorMessage !== null" style="margin-top: 20px">Forgot your password ? <a class="link" routerLink="/app-recover">Recover your password</a></p>
  </div>

  <div></div>
  <div>
    <div class="description">
      <p>This key is for a 30 days trial. After that you will be contacted by our sales team to discuss long term usage.</p>
      <p>Data Explorer is a 100% ID coverage searching tool powered by Quansic which allows you to navigate through the global music repertoire.</p>
      <p>You can connect all artists with their releases and recordings, whether they are main artists or non featured artists and know the linked IDs such as ISNI, ISRC, UPC, IPI, IPN, etc.<br/>
        Every month 10’000 new IDs are added to our platform<br/>
        You can also have access to Data Explorer via an API. To have more information you can contact our technical team who will be glad to help you.
      </p>
      <p>Enjoy your exploration.</p>
    </div>
  </div>
</div>
