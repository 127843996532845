import {Component, OnDestroy, OnInit, ViewChild, inject} from '@angular/core';
import {Title} from "@angular/platform-browser";
import {Subscription, of, throwError, combineLatest} from "rxjs";

import {ResultService} from "../services/result.service";
import {ApiError, Artist, Recording, Release, Result, Work} from '../model';
import {ApiSearchService} from "../services/api-search.service";
import {ActivatedRoute, Params, Router} from "@angular/router";
import {PerfService} from "../services/perf.service";
import {SearchComponent} from "../search/search.component";
import {LoginService} from "../services/login.service";
import { environment } from '../../environments/environment';
import {catchError, map} from "rxjs/operators";
import {ArtistService} from "../services/artist.service";
import { State, Store } from '@ngrx/store';
import { recordingActions } from '../shared/store/recording/recording.actions';
import { authActions } from '../shared/store/auth/auth.actions';

@Component({
  selector: 'app-home',
  templateUrl: './app-home.component.html',
  styleUrls: ['./app-home.component.scss']
})
export class AppHomeComponent implements OnInit, OnDestroy {

  store = inject(Store);

  // @ts-ignore
  @ViewChild(SearchComponent, { static: false }) searchComponent: SearchComponent;
  resultSubscription: Subscription;

  error: ApiError|null = null;
  errorMsg: string|null = null;

  isShowDisambig = false;
  artistsForDisambiguation: Array<Artist> = [];
  selectedArtist: Artist|null = null;
  selectedRecording: Recording|null = null;
  selectedRelease: Release|null = null;
  selectedWork: Work|null = null;
  recordings: Array<Recording> = [];
  artists: Array<Artist> = [];
  releases: Array<Release> = [];
  works: Array<Work> = [];

  isLoading = false;
  context = "home";
  searchTerm: string|null = null;

  paramsHandler: Subscription|null = null;

  currentSearchIds: any = {};

  constructor(
    private resultService: ResultService,
    private loginService: LoginService,
    private perfService: PerfService,
    private route: ActivatedRoute,
    private router: Router) {
    this.resultSubscription = this.resultService.getResult().subscribe(this.onResultHandler.bind(this));
  }

  initResults() {
    this.error = null;
    this.errorMsg = null;
    this.isShowDisambig = false;
    this.artistsForDisambiguation = [];

    this.selectedArtist = null;
    this.selectedRecording = null;
    this.selectedRelease = null
    this.selectedWork = null;
    this.artists = [];
    this.releases = [];
    this.recordings = [];
    this.works = [];
  }

  onResultHandler(result: Result): void {
    this.error = result.error;
    this.errorMsg = result.errorMsg;
    this.isShowDisambig = result.isShowDisambig;
    this.artistsForDisambiguation = result.artistsForDisambiguation;

    this.selectedArtist = result.selectedArtist;
    this.selectedRecording = result.selectedRecording;
    this.selectedRelease = result.selectedRelease
    this.selectedWork = result.selectedWork;
    this.artists = result.artists;
    this.releases = result.releases;
    this.recordings = result.recordings;
    this.works = result.works;
    this.perfService.mark("Results processed");
  }

  ngOnInit(): void {
    this.store.dispatch(authActions.verifysession());
  }

  ngOnDestroy(): void {
    if (this.paramsHandler) this.paramsHandler.unsubscribe();
  }

  handleQueryParams() {
    if (!this.route.queryParams) return of(null);

    return this.route.queryParams.pipe(
      map((params:any) => {
        const key = params.key;
        const isAuthenticated = this.loginService.isAuthenticated();

        if(!isAuthenticated && key){
          if(typeof(this.loginService.login) === "undefined" || !this.loginService || !this.loginService.login) return;
          return this.loginService.login(key)?.subscribe(
            () => { //success
              this.lookupId()
            },
            () => { //error
              this.resultService.error(ApiError.UNAUTHORIZED, "Invalid key");
            }
          );
        }
        if(!isAuthenticated && !key) {
         this.router.navigate(['/app-login']); // change this to show the error page.
        }
      }),
      catchError((err) => {
        console.error(err);
        return throwError(err);
      })
    ).subscribe();
  }

  handleParams() {
    if(!this.route.params) return of(null);

    this.paramsHandler =  this.route.params.subscribe((params: Params) => {
      // this.currentSearchIds = {idType: params['idType'], id: params['id']};
      // if(this.loginService.isAuthenticated()) {
      //   // this.lookupId();
      // }
    })

    return this.paramsHandler;
  }

  lookupId(): void {

    // const id: string = this.currentSearchIds.id;
    // this.lookupIsrc(id);
      // const idType: string = this.currentSearchIds.idType;
      // const id: string = this.currentSearchIds.id;
      // if (idType != null && id != null) {
      //   this.context = "search";
      //   if (idType === 'name') {
      //     this.searchByName(id);
      //   }
      //   if (idType === "quansicId") {
      //     this.lookupQuansicId(id);
      //   }

      //   if (idType === 'isni') {
      //     this.searchByIsni(id);
      //   }
      //   if (idType === 'musicBrainz-id') {
      //     this.searchByMusicBrainzId(id);
      //   }
      //   if (idType === 'discogs-id') {
      //     this.searchByDiscogsId(id);
      //   }
      //   if (idType === 'apple-id') {
      //     this.searchByAppleId(id);
      //   }
      //   if (idType === 'spotify-id') {
      //     this.searchBySpotifyId(id);
      //   }
      //   if (idType === 'wikidata-id') {
      //     this.searchByWikidataId(id);
      //   }
      //   if (idType === 'amazon-id') {
      //     this.searchByAmazonId(id);
      //   }
      //   if (idType === 'deezer-id') {
      //     this.searchByDeezerId(id);
      //   }
      //   if (idType === 'ipn') {
      //     this.searchByIpn(id);
      //   }
      //   if(idType === 'upc'){
      //     this.lookupUpc(id);
      //   }
      //   if(idType === 'isrc'){
      //     this.lookupIsrc(id);
      //   }
      //   if(idType === 'iswc'){
      //     this.lookupIswc(id);
      //   }
      //   if(idType === 'bowi'){
      //     this.lookupBowi(id);
      //   }

      //   if (idType === 'ipi') {
      //     this.searchByIpi(id);
      //   }
      // } else {
      //   if (this.isDemo()) {
      //     this.router.navigate(['/app-home/name', 'demo']);
      //   }
      //   this.context = "home";
      // }
  }

  // searchByName(artistName: string): void{
  //   if (artistName != null && artistName !== '') {
  //     this.searchTerm = artistName;//this.searchComponent?.getSearchTerm() || null;
  //     this.apiSearchService.searchByName(artistName);
  //   }
  // }

  // lookupQuansicId(quansicId: string): void {
  //   this.apiSearchService.lookupArtistByQuansicId(quansicId);
  // }

  // searchByIsni(isni: string): void {
  //   isni = this.artistService.formatIdRaw("isni", isni);
  //   this.apiSearchService.searchArtistsById("isni", isni)
  // }
  // searchByMusicBrainzId(musicBrainzId: string): void{
  //   this.apiSearchService.searchArtistsById("musicBrainz-id", musicBrainzId);
  // }
  // searchByDiscogsId(discogsId: string): void{
  //   this.apiSearchService.searchArtistsById("discogs-id", discogsId);
  // }
  // searchByWikidataId(wikidataId: string): void{
  //   this.apiSearchService.searchArtistsById("wikidata-id", wikidataId);
  // }
  // searchByAppleId(appleId: string): void{
  //   this.apiSearchService.searchArtistsById("apple-id", appleId);
  // }
  // searchBySpotifyId(spotifyId: string): void{
  //   this.apiSearchService.searchArtistsById("spotify-id", spotifyId);
  // }
  // searchByAmazonId(amazonId: string): void{
  //   this.apiSearchService.searchArtistsById("amazon-id", amazonId);
  // }
  // searchByDeezerId(deezerId: string): void{
  //   this.apiSearchService.searchArtistsById("deezer-id", deezerId);
  // }
  // searchByIpn(ipn: string): void{
  //   this.apiSearchService.searchArtistsById("ipn", ipn);
  // }
  // searchByIpi(ipi: string): void{
  //   this.apiSearchService.searchArtistsById("ipi", ipi);
  // }


  // lookupIsrc(isrc: string): void {
  //   // this.apiSearchService.lookupIsrc(isrc);
  //   console.log('lookupIsrc')
  //   this.storeRecording.dispatch(recordingActions.getrecordingbyisrc({isrc}));
  // }
  // lookupUpc(upc: string): void {
  //   this.apiSearchService.lookupUpc(upc);
  // }
  // lookupIswc(iswc: string): void {
  //   iswc = this.artistService.formatIdRaw("iswc", iswc);
  //   this.apiSearchService.lookupIswc(iswc);
  // }
  // lookupBowi(bowi: string): void {
  //   bowi = this.artistService.formatIdRaw("bowi", bowi);
  //   this.apiSearchService.lookupBowi(bowi);
  // }


}
