import { HttpHelperService } from 'src/app/services/http.service';
import { Entity } from './../model';
import { ResultService } from './result.service';
import { inject, Injectable } from "@angular/core";
import { BehaviorSubject, combineLatest, EMPTY, filter, map, merge, Observable, of, retry,  Subject, switchMap, tap } from "rxjs";
import { ChangeLog, ChangeLogEvent, ChangeRequest } from "./inline-edit.service";

import { HttpClient } from '@angular/common/http';
import { YChangeRequest, YChangeRequestPayload } from './y-db.model';

const CHANGE_REQUESTS_STORAGE = "CHANGE_REQUESTS_STORAGE";



@Injectable({
  providedIn: 'root'
})
export class YDBService {
  http = inject(HttpClient)
  httpHelperService = inject(HttpHelperService)
  resultService = inject(ResultService);

  entityLoad$: BehaviorSubject<Entity|null> = new BehaviorSubject<Entity|null>(null)

  version = 1;
  db:IDBDatabase | null = null;

  private bffApiUrl = this.httpHelperService.getBffDomain();  // URL to web api

  changeRequestPushed$ = new Subject<ChangeRequest>();

  changeRequests$ = merge([
    this.entityLoad$
      .pipe(
        map(entity => ({entityType: entity?.entityType, entityId: entity?.id}))
      ),
    this.changeRequestPushed$.asObservable()
      .pipe(
        map(cr => ({entityType: cr.entityType, entityId: cr.entityId}))
      )
  ]).pipe(
    switchMap((result) => result),
    switchMap(({entityId, entityType}) => {
      if(entityType === "recording" && entityId !== undefined) {
        return combineLatest([
          this.getYChangeRequestsRecording(entityId),
          this.getYChangeLogsRecording(entityId)
        ]).pipe(
          tap(([requests,logs]) => console.log("requests", requests, "logs", logs)),
          map(([requests,logs]) => requests.concat(logs)))
      }
      if(entityType === "party" && entityId !== undefined) {
        return this.getYChangeRequestsArtist(entityId)
      }
      else return of([]);
    }),
    // tap(changeRequests => console.log("changeRequests", changeRequests)),
    //
  )

  booleanAsStringJsonParser(k: string, v: string) {
     return v === "true" ? true : v === "false" ? false : v
  }

  pushChangeRequest(changeRequest: ChangeRequest): Observable<ChangeRequest> {
    if(changeRequest.entityType ==='recording') {
      return this.pushYChangeRequestIsrc(changeRequest)
        .pipe(switchMap(() => of(changeRequest)))
    }
    if(changeRequest.entityType ==='party') {
      return this.pushYChangeRequestArtist(changeRequest)
        .pipe(switchMap(() => of(changeRequest)))
    }
    else return EMPTY;
  }

  // getActiveChangeRequestsByEntityIdAndProperty$(entityId: string, property: string): Observable<ChangeRequest[]>{
  //   return this.changeRequests$.pipe(
  //     // tap(crs => console.log("change requests from Y, unfiltered", crs)),
  //     map((changeRequests: ChangeRequest[]) => changeRequests.filter((cr:ChangeRequest) => cr.entityId === entityId)),
  //     // tap(crs => console.log("change requests from Y, filtered by entity id", crs)),
  //     map((changeRequests: ChangeRequest[]) => changeRequests.filter((cr:ChangeRequest) => cr.property.endsWith(property))),
  //     // tap(crs => console.log("change requests from Y, filtered", crs)),
  //   )
  // }

  pushYChangeRequestIsrc(changeRequest: ChangeRequest): Observable<YChangeRequest> {
    const payload: YChangeRequestPayload = this.toYChangeRequestPayload(changeRequest)
    return this.http.post<YChangeRequest>(
      `${this.bffApiUrl}/api/y/recording/${changeRequest.entityId}/change-requests`, payload,
      {withCredentials: true})
      .pipe(
        tap(() => this.changeRequestPushed$.next(changeRequest))
      )
  }
  pushYChangeRequestArtist(changeRequest: ChangeRequest): Observable<YChangeRequest>  {
    const payload: YChangeRequestPayload = this.toYChangeRequestPayload(changeRequest)
    return this.http.post<YChangeRequest>(
      `${this.bffApiUrl}/api/y/party/${changeRequest.entityId}/change-requests`, payload,
      {withCredentials: true})
      .pipe(
        tap(() => this.changeRequestPushed$.next(changeRequest))
      )
  }

  getYChangeRequestsArtist(artistId: string): Observable<ChangeLog[]> {
    return this.http.get<{results: {editRequests: []}}>(
      `${this.bffApiUrl}/api/y/party/${artistId}/change-requests`,
      {withCredentials: true})
      .pipe(
        retry(1),
        map(response => response.results.editRequests),
        tap((yChangeLogs) => console.log("yChangeRequest artist", yChangeLogs)),
        // map((yChangeLogs) => yChangeLogs.map((ycl) => this.toChangeLog(ycl, artistId, "party")))
      )
  }
  getYChangeRequestsRecording(isrc: string): Observable<ChangeLog[]> {
    return this.http.get<{results: {editRequests: []}}>(
      `${this.bffApiUrl}/api/y/recording/${isrc}/change-requests`,
      {withCredentials: true})
      .pipe(
        retry(1),
        map(response => response.results.editRequests),
        // map((yChangeLogs) => yChangeLogs.map((ycl) => this.toChangeLog(ycl, isrc, "recording")))
      )
  }

  getYChangeLogsRecording(isrc: string): Observable<ChangeLog[]> {
    return this.http.get<{results: { changelogs: any[]} }>(
      `${this.bffApiUrl}/api/y/recording/${isrc}/changelogs`,
      {withCredentials: true})
      .pipe(
        retry(1),
        map(response => response.results.changelogs),
        tap(logs => console.log("logs", logs)),
        map(logs => logs?.map((log:any) => ({
          uuid: "1",
          history: [{
            status: "",
            statusBy: "",
            statusComment: "",
            timestamp: log.changeDate
          } as ChangeLogEvent],
          requester: {
              id :"Luminate",
              company: "Luminate"
          },
          id: log.entityId,
          field: {
              name: log.field.name,
              oldValue: log.field.oldValue,
              newValue: log.field.newValue
          },
          ySources: log.xySources.map((source:any) => ({
            [source.source]: source.ids
          })),
        }))),
        tap(logs => console.log("changlog", logs)),
      )
  }

  // getYChangeLogsRecording(isrc: string): Observable<ChangeLog[]> {
  //   return this.http.get<{results: {editRequests: []}}>(
  //     `${this.bffApiUrl}/api/y/recording/${isrc}/change-requests`,
  //     {withCredentials: true})
  //     .pipe(
  //       retry(1),
  //       map(response => response.results.editRequests),
  //       // map((yChangeLogs) => yChangeLogs.map((ycl) => this.toChangeLog(ycl, isrc, "recording")))
  //     )
  // }

  toYChangeRequestPayload(changeRequest: ChangeRequest): YChangeRequestPayload {
    return {
      requester: {
        id: changeRequest.user || '',
        company: 'company'
      },
      field: {
        name: changeRequest.property,
        oldValue: changeRequest.originalValue?.toString() || '',
        newValue: changeRequest.newValue?.toString() || '',
      },
      comment: changeRequest.comment || '',
      ySources: changeRequest.ySources
    }
  }



  // mapSources(sources: YSources): YSourcePayload[] {
  //   let ySourcePayload:YSourcePayload[] = [];
  //   Object.keys(sources).forEach((key: string) => {
  //     sources[key].forEach(source => ySourcePayload.push({id: key, source}))
  //   })
  //   return ySourcePayload;
  // }
}
