import { Component, inject, Input, OnDestroy, SimpleChanges } from "@angular/core";
import { EMPTY, filter, from, map, merge, Observable, of, Subject, switchMap, take, takeUntil, tap } from "rxjs";
import { Artist, ArtistLockAction, ArtistLockStatus } from "src/app/model";
import { DialogLockComponent } from "../dialog-lock/dialog-lock.component";
import { LockService } from "src/app/services/lock.service";
import { MatDialog } from "@angular/material/dialog";
import { environment } from "src/environments/environment";

declare var toastr:any;

@Component({ 
    selector: 'artist-lock',
    templateUrl: './lock.component.html',
    styleUrls: ['./lock.component.scss']
})
export class LockComponent implements OnDestroy{
    readonly lockService = inject(LockService);
    readonly dialog = inject(MatDialog);

    @Input() artist$!: Observable<Artist|null>
    destroy$ = new Subject<void>();

    
    toasterOptions: any = {
        "preventDuplicates": true,
        "positionClass": "toast-top-center",
        "timeOut": "2000",
    }

    ArtistLockStatus = ArtistLockStatus;
    ArtistLockAction = ArtistLockAction;
    _lockStatusSubject = new Subject<ArtistLockStatus>();
    lockStatus$: Observable<ArtistLockStatus> = EMPTY;

    isDataTestEnvironment: boolean = environment.dataTest;

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['artist$'] && changes['artist$'].currentValue) {
          // deal with asynchronous Observable result
          this.lockStatus$ = merge(
            this.artist$.pipe(
                takeUntil(this.destroy$),
                filter(artist => artist !== null),
                switchMap(artist => this.lockService.isLocked(artist!.ids.quansicId)),
                map(isLocked => isLocked ? ArtistLockStatus.Locked : ArtistLockStatus.Unlocked)
              ),
            this._lockStatusSubject.asObservable()
          )
        }
      }

    openLockDialog(lockStatus: ArtistLockStatus) {
        const lockAction: ArtistLockAction = 
          lockStatus === ArtistLockStatus.Locked 
            ? ArtistLockAction.Unlock 
            : ArtistLockAction.Lock;

        this.artist$.pipe(
            take(1),
            filter(artist => artist !== null),
            tap(artist => {
                this.dialog.open(DialogLockComponent, {
                    panelClass: 'dialog',
                    data: {
                      artist: artist,
                      lockAction: lockAction,
                      successCallback: (lockStatus: ArtistLockStatus) => {
                        console.log("back from locking/unlocking artist", lockStatus);
                        this._lockStatusSubject.next(lockStatus);
                        lockStatus ? this.displaySuccessLockEventMessage(artist!) : this.displaySuccessUnlockEventMessage(artist!);
                      },
                      errorCallback: (err: string) => {
                        this.displayErrorEventMessage(artist!, err);
                      }
                  }});
            })
        ).subscribe()
      }
    

    displaySuccessLockEventMessage(artist: Artist): void{
        toastr.options = this.toasterOptions;
        toastr.info(`The artist ${artist.name} has been locked`);
    }
    displaySuccessUnlockEventMessage(artist: Artist): void{
        toastr.options = this.toasterOptions;
        toastr.info(`The artist ${artist.name} has been unlocked`);
    }
    displayErrorEventMessage(artist: Artist, error: string): void{
        toastr.options = this.toasterOptions;
        toastr.error(`The artist ${artist.name} lock/unlock attempt has failed [${error}]`);
    }
}