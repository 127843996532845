<div class="works">
    <ng-container *ngIf="(firstWorks$|async) as works; else noWork">
      <h2>{{'works'|loc}}: 
        <div *ngFor="let work of works" style="display: inline">
            <span style="font-weight: 300; margin-left: 10px">{{work?.bowi||work?.iswc}}</span> 
            <span style="font-size: 0.8em; font-weight: 100; margin-left: 10px">({{work?.title}})</span>
        </div>
      </h2>
    </ng-container>
    <div class="work-table-container" *ngIf="workDataSource$|async as dataSource">
        <ng-container *ngIf="dataSource.data.length > 0; else noContributors">
            <span style="font-size: 0.8em; font-weight: 100;">{{'work.contributors.list'|loc}}</span>
            <mat-table [dataSource]="dataSource" >
                <ng-container matColumnDef="isni" >
                <mat-header-cell *matHeaderCellDef> {{'artist.isni'|loc}} </mat-header-cell>
                <mat-cell *matCellDef="let contribOfWork">{{contribOfWork.id}}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="name">
                <mat-header-cell mat-header-cell *matHeaderCellDef> {{'work.contributor'|loc}} </mat-header-cell>
                <mat-cell *matCellDef="let contribOfWork">{{contribOfWork.contributor.name}}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="role" >
                <mat-header-cell *matHeaderCellDef> {{'work.contributor.role'|loc}}  </mat-header-cell>
                <mat-cell *matCellDef="let contribOfWork">{{contribOfWork.contributor.role}}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="nationality" >
                <mat-header-cell *matHeaderCellDef> {{'work.contributor.nationality'|loc}} </mat-header-cell>
                <mat-cell *matCellDef="let contribOfWork;" >
                    {{contribOfWork.contributor.nationality}} ({{contribOfWork.contributor.nationalitySource}})
                </mat-cell>
                </ng-container>
                <mat-header-row *matHeaderRowDef="worksColumns"></mat-header-row>
                <div  style="height: 250px; overflow-y: auto">
                <mat-row matRipple *matRowDef="let contribOfWork; columns: worksColumns;"></mat-row>
                </div>
            </mat-table>
        </ng-container>
    </div>
  </div>

  
<ng-template #noWork>
    <h2>{{'work.no.work.associated'|loc}}</h2>
</ng-template>
<ng-template #noContributors>
    {{'work.no.contributor.associated'|loc}}
</ng-template>