
<ng-container *ngIf="artistStatus$|async as status">

  <ng-container *ngIf="status === 'error'">
    <ng-container *ngIf="artistError$|async as error">
      <ng-container *ngIf="searchParams$|async as searchParams">
        <h2 *ngIf="error === ApiError.INVALID_ID && searchParams.quansicId">
          {{'artist.not.found.error'|loc}} (id = {{searchParams.quansicId}}).
          {{'error.verify.identifier'|loc}} <a href="javascript: return false" (click)="openFeedbackDialog()"> {{'report.error'|loc}}</a>.
        </h2>
        <h2 *ngIf="(error === ApiError.INVALID_ID || error === ApiError.NOT_FOUND) && searchParams.idType">
          {{'artist.not.found.error'|loc}}({{searchParams.idType}} = {{searchParams.id}}).
          {{'error.verify.identifier'|loc}} <a href="javascript: return false" (click)="openFeedbackDialog()">{{'report.error'|loc}}</a>.
        </h2>
        <h2 *ngIf="error === ApiError.SERVER_ERROR || error === ApiError.UNKNOWN">
          {{'error.server.1'|loc}}
          {{'error.server.2'|loc}} <a href="javascript: return false" (click)="openFeedbackDialog()">{{'report.error'|loc}}</a>.
        </h2>
      </ng-container>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="status === 'idle' || status === 'loading'">
    <skeleton-artist></skeleton-artist>
  </ng-container>
  <ng-container *ngIf="status === 'success'">
    <div class="entity-page" *ngIf="artist$|async as artist">
        <div class="name-block">
          <h1 loc="artist">Artist:</h1>
          <div class="name">
            <h2>{{artist.name}}</h2>
            <em class="comments">{{artist.comments}}</em>
          </div>
          <button mat-icon-button class="link export" (click)="exportArtist()" [title]="'artist.export.title'|loc">
            <mat-icon [inline]="true">arrow_circle_down</mat-icon>
          </button>
          <div style="flex-grow: 1"></div>
          <artist-lock [artist$]="artist$"></artist-lock>
        </div>
        <div class="info">
         <app-artist-cover [artist]="artist"></app-artist-cover>
          <div class="information">
            <h2 loc="information"></h2>
            <div><span class="strong" loc="artist.type">Type:</span> {{artist.type}}</div>
            <div><span class="strong" loc="artist.nationality">Nationality:</span> {{artist.nationality}}</div>
            <div><span class="strong" loc="artist.dateOfBirth">Date of Birth:</span> {{artist.birthdate}}</div>
            <div><span class="strong" loc="artist.dateOfDeath">Date of Death:</span> {{artist.deathdate}}</div>
          </div>
          <div class="identifiers">
            <h2 loc="identifiers"></h2>
            <div class="identifiers-list two-columns">
              <div class="identifier isni"><span class="strong" loc="artist.isni"></span> <artist-identifier [values]="artist.ids.isnis" linkTemplate="https://isni.org/__ID__" idName="ISNI"></artist-identifier></div>
              <div class="identifier ipi"><span class="strong" loc="artist.ipi"></span>  <artist-identifier [values]="artist.ids.ipis" idName="IPI"></artist-identifier></div>
              <div class="identifier ipn"><span class="strong" loc="artist.ipn"></span> <artist-identifier [values]="artist.ids.ipns" idName="IPN"></artist-identifier></div>
              <div class="identifier discogsId"><span class="strong" loc="artist.discogsId"></span>  <artist-identifier [values]="artist.ids.discogsIds" linkTemplate="https://www.discogs.com/artist/__ID__" idName="Discogs ID"></artist-identifier></div>
              <div class="identifier musicbrainzId"><span class="strong" loc="artist.musicbrainzId"></span>  <artist-identifier [values]="artist.ids.musicBrainzIds" linkTemplate="https://musicbrainz.org/artist/__ID__" idName="MusicBrainz ID"></artist-identifier></div>
              <div class="identifier wikidataId"><span class="strong" loc="artist.wikidataId"></span>  <artist-identifier [values]="artist.ids.wikidataIds" linkTemplate="https://www.wikidata.org/wiki/__ID__" idName="Wikidata ID"></artist-identifier></div>
              <div class="identifier luminateId"><span class="strong" loc="artist.luminateId"></span> <artist-identifier [values]="artist.ids.luminateIds" idName="Luminate ID"></artist-identifier></div>
              <div class="identifier gracenoteId"><span class="strong" loc="artist.gracenoteId"></span> <artist-identifier [values]="artist.ids.gracenoteIds" idName="Gracenote ID"></artist-identifier></div>
              <div class="identifier tmsId"><span class="strong" loc="artist.tmsId"></span> <artist-identifier [values]="artist.ids.tmsIds" idName="TMS ID"></artist-identifier></div>
              <div class="identifier appleId"><span class="strong" loc="artist.appleId"></span> <artist-identifier [values]="artist.ids.appleIds" linkTemplate="https://music.apple.com/artist/__ID__" idName="Apple ID"></artist-identifier></div>
              <div class="identifier spotifyId"><span class="strong" loc="artist.spotifyId"></span>  <artist-identifier [values]="artist.ids.spotifyIds" linkTemplate="https://open.spotify.com/artist/__ID__" idName="Spotify ID"></artist-identifier></div>
              <div class="identifier deezerId"><span class="strong" loc="artist.deezerId"></span> <artist-identifier [values]="artist.ids.deezerIds" linkTemplate="https://www.deezer.com/fr/artist/__ID__" idName="Deezer ID"></artist-identifier></div>
              <div class="identifier amazonId"><span class="strong" loc="artist.amazonId"></span>  <artist-identifier [values]="artist.ids.amazonIds" linkTemplate="https://music.amazon.com/artists/__ID__" idName="Amazon ID"></artist-identifier></div>              
              <div class="identifier mergedIsni"><span class="strong" loc="artist.merged.isni"></span>  <artist-identifier [values]="artist.ids.mergedIsnis" linkTemplate="https://isni.org/__ID__" idName="Merged ISNI"></artist-identifier></div>
            </div>
          </div>
          <div class="spacer"></div>
          <!-- <div *ngIf="crtcService.isCrtcUser()">
            <changelog [entityId]="artist.ids.quansicId"></changelog>
          </div> -->
        </div>
        <div class="lists">
          <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start" (selectedTabChange)="selectedTabChange($event)"
            [selectedIndex]="tabActive">
            <mat-tab position="1" >
              <ng-template mat-tab-label>
                {{'artist.relationships'|loc}}
              </ng-template>
              <div>
                <relationships [quansicId]="artist.ids.quansicId"></relationships>
                <nameVariants [quansicId]="artist.ids.quansicId"></nameVariants>
              </div>
            </mat-tab>
            <mat-tab>
              <ng-template mat-tab-label>
                <div>{{'releases'|loc}}</div>
                <div class="data-count" *ngIf="releasesStatus$|async as status">
                  (
                    <ng-container >
                      <skeleton shape="tiny" *ngIf="status === 'idle' || status === 'loading'"></skeleton>
                    </ng-container>
                    <ng-container *ngIf="status === 'success'">
                      <span *ngIf="releases$|async as releases">{{releases.length}}</span>
                    </ng-container>
                  )
                </div>
              </ng-template>
              <table-releases [quansicId]="artist.ids.quansicId"></table-releases>
            </mat-tab>
            <mat-tab>
              <ng-template mat-tab-label>
                <div>{{'recordings'|loc}}</div>
                <div class="data-count" *ngIf="recordingsStatus$|async as status">
                  (
                  <ng-container >
                    <skeleton shape="tiny" *ngIf="status === 'idle' || status === 'loading'"></skeleton>
                  </ng-container>
                  <ng-container *ngIf="status === 'success'">
                    {{recordingsTotal$|async}}
                  </ng-container>
                  )
                </div>
              </ng-template>
              <table-recordings *ngIf="viewAllRecordings" [quansicId]="artist.ids.quansicId"></table-recordings>
              <table-top-recordings *ngIf="!viewAllRecordings" [quansicId]="artist.ids.quansicId" (viewAll)="viewAllRecordings = true"></table-top-recordings>
            </mat-tab>
            <mat-tab>
              <ng-template mat-tab-label>
                <div>{{'works'|loc}}</div>
                <div class="data-count" *ngIf="worksStatus$|async as status">
                  (
                  <ng-container >
                    <skeleton shape="tiny" *ngIf="status === 'idle' || status === 'loading'"></skeleton>
                  </ng-container>
                  <ng-container *ngIf="status === 'success'">
                    <span *ngIf="works$|async as works">{{works.length}}</span>
                  </ng-container>
                  )
                </div>
              </ng-template>
              <table-works [quansicId]="artist.ids.quansicId"></table-works>
            </mat-tab>
          </mat-tab-group>
          <div class="export">
            <button mat-icon-button class="link" (click)="openExportRowsDialog()" [disabled]="!canExport()" [title]="'artist.export.title'|loc">
              <mat-icon [inline]="true">arrow_circle_down</mat-icon>
            </button>
          </div>
        </div>
    </div>
  </ng-container>
</ng-container>
