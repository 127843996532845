import { HttpErrorResponse } from '@angular/common/http'
import { createActionGroup, props} from '@ngrx/store'
import { ApiError, NameVariant, Relationship, XAPIParty, YSources } from 'src/app/model'

export const artistActions = createActionGroup({
  source: 'Artist',
  events: {
    getArtistByQuansicId: props<{quansicId: string}>(),
    getArtistByQuansicIdSuccess: props<{xParty: XAPIParty|undefined, ySources: YSources}>(),
    getArtistByQuansicIdFailure: props<{error: ApiError|string}>(),

    getArtistsById: props<{idType: string, id: string}>(),
    getArtistsByName: props<{name: string}>(),
    getArtistsByIdManySuccess: props<{xParties: XAPIParty[], searchTerm: string|null}>(),
    getArtistsByIdSingleSuccess: props<{xParty: XAPIParty}>(),
    getArtistsByIdFailure: props<{error: ApiError|string|null, searchTerm: string|null}>(),

    getNameVariants: props<{quansicId: string}>(),
    getNameVariantsSuccess: props<{nameVariants: NameVariant[]}>(),
    getNameVariantsError: props<{error: ApiError|string}>(),

    getRelationships: props<{quansicId: string}>(),
    getRelationshipsSuccess: props<{relationships: Relationship[]}>(),
    getRelationshipsError: props<{error: ApiError|string}>(),

  }
})
