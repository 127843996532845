import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, Input, OnChanges, OnDestroy } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Observable, of, Subject, takeUntil } from "rxjs";
import { DialogCoverComponent } from "src/app/dialog-cover/dialog-cover.component";
import { Artist } from "src/app/model";
import { ImageService } from "src/app/services/image.service";
import { LocalizationService } from "src/app/shared/localization/localization.service";

@Component({
    selector: 'app-artist-cover',
    templateUrl: './artist-cover.component.html',
    styleUrls: ['./artist-cover.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ArtistCoverComponent implements OnChanges, OnDestroy{
    readonly dialog = inject(MatDialog);
    readonly imageService = inject(ImageService);
    readonly localizationService = inject(LocalizationService);
    readonly changeDetectorRef = inject(ChangeDetectorRef);

    @Input() artist!: Artist;

    defaultArtistCover = '/assets/images/artist_default.jpg';
    coverImage: string|null = null;

    onDestroy$ = new Subject();
    ngOnDestroy() {
        this.onDestroy$.next(undefined);
        this.onDestroy$.complete();
    }



    ngOnChanges(): void {
        this.buildImagePath$().pipe(takeUntil(this.onDestroy$)).subscribe({
            next: (imagePath) => {
                this.coverImage = imagePath;
                this.changeDetectorRef.markForCheck()
            }
        });
    }

    openCoverDialog() {
        this.imageService.convertImagePath$(this.artist.cover, this.artist.ids.spotifyIds[0])
            .subscribe(imagePath => {
                if(this.artist?.cover === undefined) return
                this.dialog.open(DialogCoverComponent, {
                    width: '45vw',
                    data: {
                        title: this.artist.name,
                        cover: imagePath,
                        originalUrl: this.artist.cover,
                    }
                })
            })
    }

    buildImagePath$(): Observable<string|null> {
        if(this.artist && this.artist.cover !== undefined) {
            return this.imageService.convertImagePath$(this.artist.cover, this.artist.ids.spotifyIds[0]) 
        }
        else return of(this.imageService.defaultArtistCover);
    }    
    
    getArtistCoverAlt(): string {
        return `${this.localizationService.localize('artist.cover.image')} ${this.artist.name}`;
    }
}