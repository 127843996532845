<div class="mat-typography">
    <div mat-dialog-content>
      <mat-stepper style="height:100%" color="accent">
        <mat-step style="height:100%">
          <div class="step-content" >
            <ng-template matStepLabel>{{'recording.crtc.maipl.status'|loc}}</ng-template>
            <maipl-edit-panel
              [recording]="recording" [works$]="works$"></maipl-edit-panel>
           
            <div align="end" style="margin-bottom: 10px;">
              <button mat-button (click)="cancelAndClose()">{{'recording.crtc.cancel'|loc}}</button>
              <button mat-raised-button color="warn" matStepperNext
                [matBadge]="changeRequestsCount$|async" matBadgeColor="accent">{{'recording.crtc.review'|loc}}</button>
            </div>
          </div>
        </mat-step>
        <mat-step>
          <div class="step-content">
            <ng-template matStepLabel>
              <span>{{'recording.crtc.review.commit'|loc}}</span></ng-template>
            <change-requests [comments]="crComments"></change-requests>
            
            <div align="end" style="margin-bottom: 10px;">
              <button mat-button matStepperPrevious>{{'recording.crtc.back'|loc}}</button>
              <button mat-raised-button (click)="discardAllChangeCandidates()">{{'recording.crtc.discard'|loc}}</button>
              <button mat-raised-button color="warn" (click)="commitAllChangeCandidates()">{{'recording.crtc.commit'|loc}}</button>
            </div>
          </div>
        </mat-step>

      </mat-stepper>
    </div>
</div>


