
<div class="background"></div>

<div class="container login two-col">
  <div class="left-block">
    <h1 class="hero">{{'home.data.explorer'|loc}}</h1>
    <h2>{{'home.data.explorer.by'|loc}} <strong>{{'home.data.explorer.quansic.upper'|loc}}</strong></h2>
  </div>
  <div class="right-block">
    <form [formGroup]="form" *ngIf="!emailSent">
      <div class="loginForm">
          <label style="flex-shrink: 1" for="email">{{'recover.email'|loc}}&nbsp;*</label>
          <mat-form-field appearance="fill" >
            <input matInput formControlName="email" type="email" required name="email" id="email">
            <mat-hint>{{'recover.email.hint'|loc}}</mat-hint>
          </mat-form-field>
      </div>
    </form>
    <div *ngIf="emailSent">
      <h3>{{'recover.success.1'|loc}}</h3>
      <p>{{'recover.success.2'|loc}}</p>
      <button mat-raised-button color="accent" (click)="navigateToLoginPage()" >{{'register.start.exploring'|loc}}</button>
    </div>
    <div style="display: flex; flex-direction: row; align-items: baseline; gap: 15px;"  *ngIf="!emailSent">
      <button mat-raised-button color="accent" (click)="sendRecoverPasswordEmail()" [autofocus]="true" style="width: 240px"
        [disabled]="!form.valid">
        <span *ngIf="!queryInProgress">{{'recover.send.email'|loc}}</span>
        <mat-spinner color="primary" *ngIf="queryInProgress" diameter="35" style="margin-inline: auto;"></mat-spinner>
      </button>
      <a class="link" routerLink="/app-login">{{'register.back.login'|loc}}</a>
      <mat-error *ngIf="errorMessage !== null">{{errorMessage}}</mat-error>
    </div>
  </div>

  <div></div>
  <div></div>
</div>
