import {Component, Input, OnInit, inject} from '@angular/core';
import {Router} from "@angular/router";
import {FormControl, FormGroup} from "@angular/forms";

import {SelectCriteria} from "../model";
import {LogService} from "../services/log.service";
import {ProgressService} from "../services/progress.service";
import { Store } from '@ngrx/store';
import { IdentifiersService } from '../shared/data/services/identifiers.service';
import { LocalizationService } from '../shared/localization/localization.service';

declare var $: any;

const DEFAULT_SEARCH_TYPE = "name";

@Component({
  selector: 'search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {
  private readonly store = inject(Store);
  private readonly locService = inject(LocalizationService);

  @Input() format: "full"|"mini" = "full";
  searchForm: FormGroup = new FormGroup({
    searchTerm: new FormControl(''),
    searchCriteria: new FormControl( DEFAULT_SEARCH_TYPE)
  });

  searchTerm = '';
  searchType = DEFAULT_SEARCH_TYPE;

  searchCriteria: Map<string, any[]> = new Map([
    ["artist", [
      {id: SelectCriteria.NAME, label: this.locService.localize('artist.name')}, 
      {id: SelectCriteria.ISNI, label: this.locService.localize('artist.isni')},
      {id: SelectCriteria.IPI, label: this.locService.localize('artist.ipi')},
      {id: SelectCriteria.IPN, label: this.locService.localize('artist.ipn')},
      {id: SelectCriteria.DISCOGS_ID, label: this.locService.localize('artist.discogsId')},
      {id: SelectCriteria.MUSICBRAINZ_ID, label: this.locService.localize('artist.musicbrainzId')},
      {id: SelectCriteria.APPLE_ID, label: this.locService.localize('artist.appleId')},
      {id: SelectCriteria.SPOTIFY_ID, label: this.locService.localize('artist.spotifyId')},
      {id: SelectCriteria.AMAZON_ID, label: this.locService.localize('artist.amazonId')},
      {id: SelectCriteria.WIKIDATA_ID, label: this.locService.localize('artist.wikidataId')},
      {id: SelectCriteria.DEEZER_ID, label: this.locService.localize('artist.deezerId')},
      {id: SelectCriteria.LUMINATE_ID, label: this.locService.localize('artist.luminateId')},
      {id: SelectCriteria.GRACENOTE_ID, label: this.locService.localize('artist.gracenoteId')},
      {id: SelectCriteria.TMS_ID, label: this.locService.localize('artist.tmsId')},
    ]],
    ["recording", [
      {id: SelectCriteria.ISRC, label: this.locService.localize('recording.isrc')}
    ]],
    ["release", [
      {id: SelectCriteria.UPC, label: this.locService.localize('release.upc')}
    ]],
    ["work", [
      {id: SelectCriteria.ISWC, label: this.locService.localize('work.iswc')}, 
      {id: SelectCriteria.BOWI, label: this.locService.localize('work.bowi')}
    ]]
  ]);

  contactMessage = '';

  constructor(
    private progressService: ProgressService,
    private logService: LogService,
    private router: Router,
    private identifiersService: IdentifiersService) {}

  ngOnInit(): void {
    this.initSelectIds();

    this.progressService.progressTopic.subscribe(() => {
      this.searchType = DEFAULT_SEARCH_TYPE;
      this.searchTerm = '';
    });
    this.setSearchType(SelectCriteria.NAME);
  }

  initSelectIds(): void {
    if (typeof($) !== "undefined") {
      const select = $('.select2');
      if (select && typeof select.select2 === "function") { select.select2(); }
    }
  }

  placeholder() {
    const searchCriteria = this.searchType;
    if (searchCriteria === SelectCriteria.NAME) { return "Ex: Dua Lipa"; }
    if (searchCriteria === SelectCriteria.ISNI) { return "Ex: 0000000462998427 or 0000 0004 6299 8427"; }
    if (searchCriteria === SelectCriteria.IPI) { return "Ex: 00798414000"; }
    if (searchCriteria === SelectCriteria.IPN) { return "Ex: xxxxx"; }
    if (searchCriteria === SelectCriteria.DISCOGS_ID) { return "Ex: 123456"; }
    if (searchCriteria === SelectCriteria.MUSICBRAINZ_ID) { return "Ex: 6f1a58bf-9b1b-49cf-a44a-6cefad7ae04f"; }
    if (searchCriteria === SelectCriteria.APPLE_ID) { return "Ex: ID1031397873"; }
    if (searchCriteria === SelectCriteria.SPOTIFY_ID) { return "Ex: 6M2wZ9GZgrQXHCFfjv46we"; }
    if (searchCriteria === SelectCriteria.WIKIDATA_ID) { return "Ex: 4537597"; }
    if (searchCriteria === SelectCriteria.AMAZON_ID) { return "Ex: 4537597"; }
    if (searchCriteria === SelectCriteria.DEEZER_ID) { return "Ex: 0123456789"; }
    if (searchCriteria === SelectCriteria.ISRC) { return "Ex: 4537597"; }
    if (searchCriteria === SelectCriteria.ISWC) { return "Ex: T0045882345 or T-004.588.234-5"; }
    if (searchCriteria === SelectCriteria.BOWI) { return "Ex: B1ZKXYPB380 or B1-ZKXYPB38-0"; }
    if (searchCriteria === SelectCriteria.UPC) { return "Ex: 5059460071841"; }
    return ;
  }

  onChange(): void
  {
    const value = this.getSearchTerm();
    if (this.identifiersService.isIsni(value)) { return this.setSearchType(SelectCriteria.ISNI); }
    if (this.identifiersService.isIsrc(value)) { return this.setSearchType(SelectCriteria.ISRC); }
    if (this.identifiersService.isIswc(value)) { return this.setSearchType(SelectCriteria.ISWC); }
    if (this.identifiersService.isBowi(value)) { return this.setSearchType(SelectCriteria.BOWI); }
    if (this.identifiersService.isUpc(value)) {  return this.setSearchType(SelectCriteria.UPC); }
    if (this.identifiersService.isDeezerId(value)) {  return this.setSearchType(SelectCriteria.DEEZER_ID);  }
    if (this.identifiersService.isMusicBrainzId(value)) { return this.setSearchType(SelectCriteria.MUSICBRAINZ_ID); }
    // this.setSearchType(SelectCriteria.NAME);
  }
  setSearchType(selectCriteria: SelectCriteria) {
    this.searchForm.get('searchCriteria')?.setValue(selectCriteria);
    this.searchType = selectCriteria;
  }
  setSearchTypeKeyPress(event: KeyboardEvent, selectCriteria: SelectCriteria) {
    this.setSearchType(selectCriteria);
  }

  getSearchTerm(): string|null{
    return this.searchForm.get('searchTerm')?.value;
  }

  search(): Promise<boolean> {
    const searchTerm = this.getSearchTerm();
    let routeAction: string[] | null  = null;

    if (searchTerm != null && searchTerm !== "") {
      const searchCriteria = this.searchForm.get('searchCriteria')!.value;
      this.logService.recordEntitySearch(searchCriteria, searchTerm);
      switch(searchCriteria) {
        case SelectCriteria.ISRC:
          routeAction = ['/app-recording', this.identifiersService.normalizeRecordingIdentifier(searchTerm)];
          break;
        case SelectCriteria.ISWC:
        case SelectCriteria.BOWI:
          routeAction = ['/app-work', this.identifiersService.normalizeWorkIdentifier(searchTerm)];
          break;
        case SelectCriteria.UPC:
          routeAction = ['/app-release', searchTerm];
          break;
        case SelectCriteria.ISNI:
        case SelectCriteria.IPI:
        case SelectCriteria.IPN:
        case SelectCriteria.DISCOGS_ID:
        case SelectCriteria.MUSICBRAINZ_ID:
        case SelectCriteria.APPLE_ID:
        case SelectCriteria.SPOTIFY_ID:
        case SelectCriteria.WIKIDATA_ID:
        case SelectCriteria.AMAZON_ID:
        case SelectCriteria.DEEZER_ID:
        case SelectCriteria.LUMINATE_ID:
        case SelectCriteria.GRACENOTE_ID:
        case SelectCriteria.TMS_ID:
          routeAction = ['/app-party', searchCriteria, this.identifiersService.normalizeArtistIdentifier(searchCriteria, searchTerm)];
          break;
        case SelectCriteria.NAME:
          routeAction = ['/app-disambiguation', searchTerm];
      }
    }
    if(routeAction !== null) return this.router.navigate(routeAction)
    else return Promise.resolve(false);
  }

  getCriteriaLabelById(id: string): string {
    let label = "";
    this.searchCriteria.forEach((value: any[], key: string) => {
      const criteria = value.find((crit: any) => crit.id === id);
      if (criteria) { label = criteria.label; }
    });
    return label;
  }
}
