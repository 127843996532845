<div class="layout-grid">
  <maipl-score-edit [recording]="recording"></maipl-score-edit>
  <maipl-properties-edit [recording]="recording"></maipl-properties-edit>
  <div style="max-height: 80%; overflow-y: scroll; display: flex; gap: 1.5em; flex-direction: column;">
    <recording-contributors [recording]="recording"></recording-contributors>
    <work-contributors [recording]="recording"></work-contributors>
  </div>
</div>
<ng-template #noNationality><em>not defined</em></ng-template>



