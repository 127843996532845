import {Injectable} from '@angular/core';

import {
  Artist,
  ArtistResult, Entity, Identifiers,
  Recording,
  RecordingOfWork,
  Release,
  Result, Work,
  WorkOfRecording,
  XAPIParty,
  XAPIRecording,
  XAPIRelease, XAPIWork, YSources
} from "../model";

import {BehaviorSubject, Observable, Subject} from "rxjs";
import { toWorkFromXApiWork } from '../shared/store/work/work.domain';
import { toReleaseFromXApiRelease } from '../shared/store/release/release.domain';
import { formatIdentifiers, toArtistFromXParty } from '../shared/store/artist/artist.domain';
import { toRecordingFromXAPIRecording } from '../shared/store/recording/recording.domain';

@Injectable({
  providedIn: 'root'
})
export class ResultService {
  result: Result;
  resultTopic = new BehaviorSubject<Result>(this.defaultResult());
  artistResultTopic = new Subject<ArtistResult>();

  
  constructor() {
    this.result = this.defaultResult();
  }

  defaultResult() {
    return {
      error: null,
      errorMsg: null,
      isShowDisambig: false,
      nameDisambigQuery: "",
      artistsForDisambiguation: [] as Artist[],
      artists: [] as Artist[],
      recordings: [] as Recording[],
      releases: [] as Release[],
      selectedArtist: null,
      selectedRecording: null,
      selectedRelease: null,
      selectedWork: null
    } as Result
  }

  // cleanPLineString(pLine: string): string{
  //   return pLine.replace("(P)", "").replace('\u24C5', "").replace('℗', "");
  // }

  // getFirstIsni(xApiParty: XAPIParty): string {
  //  return  xApiParty.ids.isnis ? xApiParty.ids.isnis[0] : "";
  // }

  // toRelationships(apiArtist: XAPIParty): Relationships {
  //   return {
  //     hasMember: apiArtist.relationships ? apiArtist.relationships?.filter((rel: XAPIParty) => rel.relationshipType === "HasMember") : [],
  //     isMemberOf: apiArtist.relationships ? apiArtist.relationships?.filter((rel: XAPIParty) => rel.relationshipType === "IsMemberOf") : [],
  //     aka: apiArtist.relationships ? apiArtist.relationships?.filter((rel: XAPIParty) => rel.relationshipType === "Aka") : []
  //   };
  // }

  // toRelationships(relationships: Relationship[]): Relationships {
  //   return {
  //     hasMember: relationships ? relationships?.filter((rel: Relationship) => rel.relationshipType === "HasMember") : [],
  //     isMemberOf: relationships ? relationships?.filter((rel: Relationship) => rel.relationshipType === "IsMemberOf") : [],
  //     aka: relationships ? relationships?.filter((rel: Relationship) => rel.relationshipType === "Aka") : []
  //   };
  // }





  // toRecordingMaplScore(recording: XAPIRecording) {
  //   console.log("toRecordingMaplScore", recording.crtcMaplStatus)
  //   return recording.crtcMaplStatus?.classifications['Quansic']
  // }

  // toRecordingsOfWorkFromXAPIRecording(xRecordings: XAPIRecording[]): RecordingOfWork[] {
  //   return xRecordings
  //     .map(xRecording => toRecordingFromXAPIRecording(xRecording))
  //     .map((recording: Recording, idx: number) => ({q2Score: xRecordings[idx].q2Score, ...recording} as RecordingOfWork))
  // }

  toRecordingsFromAPIRelease(apiRelease: XAPIRelease) {
    if (apiRelease.recordings !== undefined)
      return apiRelease.recordings.map((apiRecording:XAPIRecording) => {
        return {
          isrc: apiRecording.isrc,
          entityType: 'recording',
          appleId: apiRecording.appleId,
          spotifyId: apiRecording.spotifyId,
          title: apiRecording.title,
          subtitle: apiRecording.subtitle,
          duration: apiRecording.durationMs,
          audioUrl: apiRecording.audioUrl,
          contributors: apiRecording.contributors
        } as Recording;
      })
    else return []
  }


  toWorksOfRecordingFromXAPIWorks(xApiWorks: XAPIWork[]): WorkOfRecording[] {
    return xApiWorks.map(xWork => toWorkFromXApiWork(xWork)).map((work: Work, idx: number) => {
      return {q2Score: xApiWorks[idx].q2Score, ...work} as WorkOfRecording
    })
  }

  toReleasesFromXApiRecording(xApiRecording: XAPIRecording):Release[] {
    if (xApiRecording.releases !== undefined)
      return xApiRecording.releases.map((release:XAPIRelease) => {
        return {
          upc: release.upc,
          entityType: 'release',
          type: release.type,
          title: release.title,
          country: "",
          year: release.year,
          cover: release.cover
        } as Release;
      })
    else return []
  }



  publishArtistsForDisambiguation(artists: Array<XAPIParty>, query: string): void{
    this.resetResult();
    this.result.isShowDisambig = true;
    this.result.nameDisambigQuery = query;
    this.result.artistsForDisambiguation = artists.map((party: XAPIParty): Artist => {
      return {
        id: party.ids.quansicId,
        entityType: 'party',
        name: party.name,
        type: party.type,
        comments: party.comment,
        birthdate: party.birthdate,
        nationality: party.nationality || "",
        cover: party.visual,
        popularity: party.popularity,
        ids: party.ids as Identifiers,
        ySources: {}
      };
    });
    this.result.artistsForDisambiguation.sort((a1: Artist, a2: Artist): number => {
      if (a1.cover == null && a2.cover != null) return 1;
      if (a1.cover != null && a2.cover == null) return -1;
      return 0;
    })
    this.pushResult(this.result);
  }


  pushAPIArtist(xApiParty: XAPIParty, ySources: YSources): void{
    this.resetResult();
    this.result.isShowDisambig = false;
    this.result.selectedArtist = {
      id: xApiParty.ids.quansicId,
      entityType: 'party',
      name: xApiParty.name,
      firstname: xApiParty.firstname,
      lastname: xApiParty.lastname,
      type: xApiParty.type,
      nationality: xApiParty.nationality || "",
      comments: xApiParty.comment,
      cover: xApiParty.visual || "",
      ids: formatIdentifiers(xApiParty.ids),
      birthdate: xApiParty.birthdate,
      deathdate: xApiParty.deathdate,
      // relationships: this.toRelationships(xApiParty),
      // namevariants: xApiParty.nameVariants,
      ySources: ySources
    } as Artist;
    this.result.recordings = xApiParty.recordings ? xApiParty.recordings.map(recording => toRecordingFromXAPIRecording(recording)): [];
    this.result.releases = xApiParty.releases ? xApiParty.releases.map(release => toReleaseFromXApiRelease(release)): [];
    this.result.works = xApiParty.works ? xApiParty.works.map(work => toWorkFromXApiWork(work)): [];
    this.pushResult(this.result);
  }

  publishAPIRecordings(xApiRecordings: XAPIRecording[]): void {
    this.result.recordings = xApiRecordings.map(recording => toRecordingFromXAPIRecording(recording));
    this.pushResult(this.result);
  }

  publishISRC(isrc: string, apiRecording: XAPIRecording|null, ysources: YSources): void {
    this.resetResult();
    if(apiRecording != null) {
      this.result.selectedRecording = toRecordingFromXAPIRecording(apiRecording, ysources) ;
      this.result.artists = apiRecording.contributors ? apiRecording.contributors.map(xParty => toArtistFromXParty(xParty)): [];
      this.result.releases = this.toReleasesFromXApiRecording(apiRecording)
      this.result.works = this.toWorksOfRecordingFromXAPIWorks(apiRecording.works || [])
    } else {
      this.result.selectedRecording = this.emptyRecording();
    }
    this.pushResult(this.result);
  }


  publishUPC(upc:string, apiRelease:XAPIRelease|null) {
    this.resetResult();
    if(apiRelease != null) {
      this.result.selectedRelease = toReleaseFromXApiRelease(apiRelease);
      this.result.artists = apiRelease.parties ? apiRelease.parties.map(xParty => toArtistFromXParty(xParty)): [];
      this.result.recordings = this.toRecordingsFromAPIRelease(apiRelease);
    } else {
      this.result.selectedRelease = this.emptyRelease();
    }
    this.pushResult(this.result);
  }

  // publishWork(apiWork: XAPIWork|null): void {
  //   this.resetResult();
  //   if(apiWork != null) {
  //     this.result.selectedWork = toWorkFromXApiWork(apiWork);
  //     this.result.recordings = this.toRecordingsOfWorkFromXAPIRecording(apiWork.recordings || []);
  //   } else {
  //     this.result.selectedWork = this.emptyWork();
  //   }
  //   this.pushResult(this.result);
  // }






  pushResult(result: Result){
    this.resultTopic.next(result);
  }
  getResult(): Observable<Result> {
    return this.resultTopic.asObservable();
  }
  getArtistResult(): Observable<ArtistResult> {
    return this.artistResultTopic.asObservable();
  }

  resetResult(): void {
    this.result = this.defaultResult();
  }

  error(errorCode: any, message?: string): void{
    this.resetResult();
    this.result.error = errorCode;
    if (message) { this.result.errorMsg = message; }
    this.pushResult(this.result);
  }

  errorId(errorCode: any, idType?: string, id?: string): void{
    this.resetResult();
    this.result.error = errorCode;
    if (idType && id) { this.result.errorId = { idType, id}; }
    this.pushResult(this.result);
  }


  emptyXApiParty(): XAPIParty {
    return {
      name: "",
      firstname: "",
      lastname: "",
      type: "",
      ids: this.emptyIdentifiers(),
      nationality: "",
      relationships: [],
      birthdate: "",
      deathdate: "",
      role: "",
      contributorType: "",
      comment: "",
      nameVariants: [],
      recordings: [],
      releases: [],
      works: [],
      popularity: 0,
      relationshipType: "",
      visual: ""
    }
  }

  emptyArtist(): Artist {
    return {
      id: "",
      name: "",
      entityType: 'party',
      type: "",
      comments: "",
      cover: "",
      ids: this.emptyIdentifiers(),
      nationality: "",
      relationships: {
        isMemberOf: [],
        hasMember: [],
        aka: []
      },
      namevariants: [],
      ySources: {}
    }
  }

  emptyIdentifiers(): Identifiers {
    return {
      quansicId: "",
      isnis: [],
      mergedIsnis: [],
      ipis: [],
      ipns: [],
      musicBrainzIds: [],
      discogsIds: [],
      appleIds: [],
      spotifyIds: [],
      wikidataIds: [],
      amazonIds: [],
      deezerIds: [],
      luminateIds: [],
      gracenoteIds: [],
      tmsIds: [],
    }
  }

  emptyRelease(): Release {
    return {
      id: '',
      upc: "",
      entityType: 'release',
      type: "",
      title: "",
      year: "",
      country: "",
      cover: "",
      coverImage: "",
      label: "",
      active: true
    }
  }

  emptyRecording(): Recording {
    return {
      id: '',
      isrc: "",
      entityType: 'recording',
      appleId: "",
      spotifyId: "",
      title: "",
      year: 0,
      country: "",
      subtitle: "",
      duration: 0,
      contributors: [],
      contributorType:"",
      audioUrl: "",
      pLine: "",
      active: true,
      ySources: {},
      crtcPropertiesStatus: {
        language:null,
        subCategory:null,
        isEmerging:null,
      },
    }
  }

  emptyXAPIRecording(): XAPIRecording {
    return {
      isrc: "",
      title: "",
      subtitle: "",
      year: 0,
      country: "",
      durationMs: 0,
      contributors: [],
      contributorType:"",
      audioUrl: "",
      pLine: "",
      active: true
    }
  }

  emptyWork(): Work {
    return {
      id: '',
      entityType: 'work',
      iswc: "",
      bowi: "",
      title: "",
      role: "",
      recordings: []
    }
  }
}


